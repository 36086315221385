import styled from 'styled-components';
import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocale } from 'hooks';
import { getEvolutionChartData } from 'modules/networkLoading';
import { portfolioIdSelector, scenarioIdSelector, selectedChartFlexSelector } from 'modules/layouts/selectors';
import { setLayoutAction } from 'modules/layouts';
import { Checkbox } from 'components/_common';
import Chart, { getBaseOptions } from '../Chart';
import SelectEvolutionType, { SelectEvolutionTypes } from './SelectEvolutionType';

interface ChartDataProps {
  title: string;
  xAxisTitle: string;
  yAxisTitle: string;
  series: Shared.SeriesOptionsType[];
  categories: string[];
}

interface Props {
  uuid: string;
  height?: '100%';
  isTooltipOutside?: boolean;
}

const EvolutionChart: React.FC<Props> = ({ uuid, height, isTooltipOutside = false }) => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [chartDataHash, setChartDataHash] = useState<Record<SelectEvolutionTypes, ChartDataProps> | null>(null);
  const [type, setType] = useState(SelectEvolutionTypes.Power);
  const flex = useSelector(selectedChartFlexSelector);
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);
  const chartData = chartDataHash?.[type];
  const baseOptions = getBaseOptions(getIntl, chartData!);

  const handleSelectEvolutionTypeChange = useCallback(
    (option: Type.SelectOption<SelectEvolutionTypes>) => setType(option.value),
    []
  );

  const handleCheckboxClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      dispatch(setLayoutAction({ selectedChartFlex: event.currentTarget.checked })),
    [dispatch]
  );

  useEffect(() => {
    setChartDataHash(null);
    if (!portfolioId || !scenarioId || !uuid) return;
    dispatch(getEvolutionChartData({ portfolioId, scenarioId, uuid, flex })).then(action =>
      setChartDataHash(action.payload)
    );
  }, [dispatch, portfolioId, scenarioId, uuid, flex]);

  const options = useMemo(
    () => ({
      ...baseOptions,
      tooltip: {
        outside: isTooltipOutside,
      },
      xAxis: {
        ...baseOptions.xAxis,
        categories: chartData?.categories?.map(c => getIntl(c)),
      },
      series: chartData?.series?.map(s => ({ ...s, name: getIntl(s.name || '') })) || [],
    }),
    [baseOptions, chartData, getIntl, isTooltipOutside]
  ) as unknown as Highcharts.Options;

  return (
    <>
      <StyledContainer data-marker="asset_evolution_chart__buttons_block">
        <SelectEvolutionType
          labelKey=""
          value={type}
          onChange={handleSelectEvolutionTypeChange}
          variant="small"
          className="mr-1"
        />
        <Checkbox
          labelKey="Flex"
          className="icheck-primary ml-1"
          name="flex"
          checked={flex}
          onChange={handleCheckboxClick}
        />
      </StyledContainer>

      <Chart key={type} dataMarker="asset_evolution_chart" options={chartData ? options : null} height={height} />
    </>
  );
};

const StyledContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: 32px;
  right: 50%;
  transform: translate(50%);
  display: inline-flex;
  justify-content: space-between;
`;

export default EvolutionChart;
