import React, { useCallback, useState, useEffect } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { useLocale, useEventPreventedExec } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import { setLayoutAction } from 'modules/layouts';
import { overviewScenarioModalMetaSelector } from 'modules/layouts/selectors';
import {
  defaultForecastedScenarioNameSelector,
  forecastedScenarioSelectorFactory,
  isMaxForecastedScenariosReachedSelector,
} from 'modules/setup/selectors';
import { appIsAdminUserSelector } from 'modules/app/selectors';
import { Modal, Button, FormInput, HiddenFormSubmit, Checkbox, SelectScenarioYearsPossible } from 'components/_common';
import { IconPlus } from '@utiligize/shared/resources';
import useConfiguredFormik from './useConfiguredFormik';
import PortfolioInfo from './PortfolioInfo';
import { MAX_FORECASTED_SCENARIOS } from 'constants/index';

const ModalForecastedScenarios: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { id, isDuplicate }: Layouts.ModalMeta = useSelector(overviewScenarioModalMetaSelector) || {
    id: null,
    isDuplicate: false,
  };
  const isMaxForecastedScenariosReached = useSelector(isMaxForecastedScenariosReachedSelector);
  const scenario: Setup.ForecastedScenario | undefined = useSelector(forecastedScenarioSelectorFactory(id));
  const defaultForecastedScenarioName = useSelector(defaultForecastedScenarioNameSelector);
  const isEditMode: boolean = Boolean(id && scenario);
  const appIsAdminUser = useSelector(appIsAdminUserSelector);
  const [show, setShow] = useState(false);

  const toggleModal = useCallback((): void => {
    // Reset redux layouts state for isEditMode
    if (show && id) dispatch(setLayoutAction({ overviewScenarioModalMeta: null }));
    setShow(!show);
  }, [id, show, dispatch]);

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    submitForm,
    resetForm,
    setValues,
    setFieldValue,
    setFieldTouched,
  } = useConfiguredFormik({ id, toggleModal, isDuplicate });
  const handleFormSubmit = useEventPreventedExec(submitForm);

  useEffect(() => {
    if (!show) resetForm();
  }, [show, resetForm]);

  useEffect(() => {
    if (!scenario) return;
    setValues({
      description: isDuplicate ? `${scenario.description} (${getIntl('copy')})` : scenario.description,
      years: scenario.years,
      default: isDuplicate ? false : scenario.default,
    });
    setShow(true);
  }, [scenario, isDuplicate, getIntl, setValues]);

  const handleSelectYearSelectChange = useCallback(
    (options: Type.SelectOption[]) => setFieldValue('years', options?.map(i => i.value) || null),
    [setFieldValue]
  );

  return (
    <>
      <Button
        icon={<IconPlus />}
        labelKey="Create scenario"
        onClick={toggleModal}
        variant="primary"
        disabled={isMaxForecastedScenariosReached}
        tooltipKey={
          isMaxForecastedScenariosReached
            ? getIntl('Maximum {{max}} scenarios can be created, please delete one to create a new scenario', {
                max: MAX_FORECASTED_SCENARIOS,
              })
            : ''
        }
      />
      <Modal
        show={show}
        onHide={toggleModal}
        titleKey={isEditMode ? (isDuplicate ? 'Duplicate scenario' : 'Edit scenario') : 'Create scenario'}
        cancelButtonProps={{
          disabled: isSubmitting,
          onClick: toggleModal,
        }}
        submitButtonProps={{
          labelKey: isEditMode ? (isDuplicate ? 'Duplicate' : 'Update') : 'Create',
          loading: isSubmitting,
          onClick: handleFormSubmit,
        }}
      >
        <Form onSubmit={handleFormSubmit}>
          <Form.Group>
            <FormInput
              labelKey="Scenario name"
              name="description"
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
              errorKey={Boolean(touched.description && errors.description) ? (errors.description as string) : ''}
              isNew
            />
          </Form.Group>
          {appIsAdminUser && (
            <>
              <Form.Group>
                <Checkbox
                  className="icheck-primary d-inline"
                  name="default"
                  checked={values.default}
                  labelKey="Default"
                  onChange={handleChange}
                />
                {values.default && (
                  <Alert className="mt-3 mb-0" variant="light">
                    <small>
                      {getIntl('Your previously selected default scenario {{name}} will be replaced', {
                        name: defaultForecastedScenarioName,
                      })}
                      .
                    </small>
                  </Alert>
                )}
              </Form.Group>
            </>
          )}
          <Form.Group>
            <SelectScenarioYearsPossible
              isMulti
              name="years"
              value={values.years as any}
              onChange={handleSelectYearSelectChange}
              onBlur={() => setFieldTouched('years')}
              errorKey={Boolean(touched.years && errors.years) ? (errors.years as string) : ''}
              showAllAvailableYearsCheckbox
            />
          </Form.Group>
          <PortfolioInfo />
          <HiddenFormSubmit />
        </Form>
      </Modal>
    </>
  );
};

export default ModalForecastedScenarios;
