import React, { createContext, useCallback, useEffect, useMemo } from 'react';
import mixpanel from 'mixpanel-browser';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { mixpanelPageViewEventPayloadSelector } from 'modules/composite.selectors';
import {
  mapStateCollapseGroupStateSelector,
  mapStateThemeGroupSelector,
  mapStateThemeSelector,
} from 'modules/map/selectors';
import { isRouteActiveSelectorFactory } from 'modules/router/selectors';
import { trackPageViewAction, trackMapLayersAction } from 'modules/app';
import { getStorageItem } from 'utils';
import { isProduction, Routes, StorageKeys } from 'constants/index';

export const MixpanelContext = createContext<{
  trackCustomEvent: (name: string, payload: Record<string, any>) => void;
}>({ trackCustomEvent: () => null });

if (process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN as string, {
    debug: !isProduction,
    api_host: 'https://cheese.utiligize.com',
    ignore_dnt: true,
  });
}

const MixpanelContextProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { pathname, hash } = useLocation();
  const currentRoute = `${pathname}${hash}`;
  const lastRoute = getStorageItem(StorageKeys.LAST_ROUTE);
  const skipTracking = !useSelector(mixpanelPageViewEventPayloadSelector);
  const collapseGroupState = useSelector(mapStateCollapseGroupStateSelector);
  const themeGroup = useSelector(mapStateThemeGroupSelector);
  const theme = useSelector(mapStateThemeSelector);
  const isPageMap = useSelector(isRouteActiveSelectorFactory(Routes.Map));
  const isDebugEnabled = localStorage.getItem('DEBUG_ENABLED');

  const mapEventPayload = useMemo(() => {
    const map_layers = Object.keys(collapseGroupState || {}).reduce((acc: string[], key) => {
      if (!collapseGroupState[key]) return acc;
      acc.push(key);
      return acc;
    }, []);

    if (!themeGroup && !theme && !map_layers.length) return null;
    return {
      map_theme_group: themeGroup,
      map_theme: theme,
      map_layers,
    };
  }, [themeGroup, theme, collapseGroupState]);

  const trackPageView = useCallback(() => {
    if (isDebugEnabled) console.info('Dashboard page view event');
    dispatch(trackPageViewAction());
  }, [isDebugEnabled, dispatch]);

  const trackCustomEvent = useCallback(() => {
    if (isDebugEnabled) console.info('trackCustomEvent');
  }, [isDebugEnabled]);

  // MixpanelEventTypes.PAGE_VIEW_EVENT
  useEffect(() => {
    if (!skipTracking) trackPageView(); // To track the first page view upon load
  }, [skipTracking, trackPageView]);

  useEffect(() => {
    if (skipTracking || currentRoute === lastRoute) return;
    trackPageView();
  }, [skipTracking, currentRoute, lastRoute, trackPageView]);

  // MixpanelEventTypes.MAP_LAYERS
  useEffect(() => {
    if (skipTracking || !isPageMap || !mapEventPayload) return;
    if (isDebugEnabled) console.info('Dashboard map layer event', mapEventPayload);
    dispatch(trackMapLayersAction(mapEventPayload));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skipTracking, JSON.stringify(mapEventPayload).length]);

  return <MixpanelContext.Provider value={{ trackCustomEvent }}>{children}</MixpanelContext.Provider>;
};

export default MixpanelContextProvider;
