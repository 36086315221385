import styled from 'styled-components';
import React, { useMemo } from 'react';
import { useLocale } from 'hooks';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';

export enum SelectEvolutionTypes {
  Power = 'Power',
  Voltage = 'Voltage',
  Losses = 'Losses',
}

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: SelectEvolutionTypes;
}

const SelectEvolutionType: React.FC<Props> = ({ value = null, ...props }) => {
  const { getIntl } = useLocale();

  const { options, selectValue } = useMemo(
    () =>
      Object.keys(SelectEvolutionTypes).reduce(
        (acc: any, key: string) => {
          const item = { value: key, label: getIntl(key) };
          if (value === key) acc.selectValue = item;
          acc.options.push(item);
          return acc;
        },
        { options: [], selectValue: null }
      ),
    [value, getIntl]
  );

  return <StyledFormReactSelect value={selectValue} options={options} isSearchable={false} {...props} />;
};

const StyledFormReactSelect = styled(FormReactSelect)`
  min-width: 110px;
`;

export default SelectEvolutionType;
