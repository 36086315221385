import React, { useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useLocale } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { setLayoutAction } from 'modules/layouts';
import { isCO2eCumulativeOverviewSelector, CO2eScenariosYearSelector } from 'modules/layouts/selectors';
import { isRouteActiveSelectorFactory } from 'modules/router/selectors';
import { Checkbox, SelectScenarioYears } from 'components/_common';
import { Routes } from 'constants/index';

const Filters: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const isCO2eCumulativeOverview = useSelector(isCO2eCumulativeOverviewSelector);
  const CO2eScenariosYear = useSelector(CO2eScenariosYearSelector);
  const isPageCO2eOverview = useSelector(isRouteActiveSelectorFactory(Routes.CO2e));

  const handleCheckboxClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setLayoutAction({ isCO2eCumulativeOverview: event.currentTarget.checked }));
    },
    [dispatch]
  );

  const handleSelectChange = useCallback(
    (value: Type.SelectOption) => {
      dispatch(setLayoutAction({ CO2eScenariosYear: value?.value as number }));
    },
    [dispatch]
  );

  return (
    <Row>
      {isPageCO2eOverview && (
        <Col sm={2}>
          <SelectScenarioYears
            name="year"
            value={CO2eScenariosYear}
            onChange={handleSelectChange}
            labelKey=""
            mutedTextLabelKey="Scenarios year"
            variant="small"
          />
        </Col>
      )}
      <Col xs="auto">
        <Checkbox
          labelKey="Cumulative"
          className="icheck-primary"
          checked={isCO2eCumulativeOverview}
          onChange={handleCheckboxClick}
        />
      </Col>
      <Col xs="auto" className="d-flex ml-auto">
        <small className="text-muted">
          <span
            dangerouslySetInnerHTML={{
              __html: getIntl('Losses based on 99.9<sup>th</sup> percentile results are displayed'),
            }}
          />
        </small>
      </Col>
    </Row>
  );
};

export default Filters;
