import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { uploadMaterialsExcelAction } from 'modules/tasks';

const useConfiguredFormik = ({ toggleModal }: { toggleModal: () => void }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const formik = useFormik<Type.ModalUploadMaterialsValues>({
    initialValues: { replace: false, file: null },

    validationSchema: Yup.object().shape({
      file: Yup.mixed().required('Please upload materials excel first'),
    }),

    onSubmit: values => {
      return dispatch(uploadMaterialsExcelAction(values)).then(toggleModal).catch(console.error);
    },
  });

  return formik;
};

export default useConfiguredFormik;
