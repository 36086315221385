import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';
import { OverlayTrigger, Popover, ListGroup } from 'react-bootstrap';
import { useLocale } from 'hooks';
import { fetchHistoryAction } from 'modules/history';
import { historyItemsSelector, historyCountSelector } from 'modules/history/selectors';
import { portfolioIdSelector } from 'modules/layouts/selectors';
import { simulationVersionIdSelector } from 'modules/options/selectors';
import { isLiveDataConnectionSelector } from 'modules/app/selectors';
import { DataTable, Button, ChecklistStatus } from 'components/_common';
import { PaginationType, Routes } from 'constants/index';
import { IconAnswer, IconReplace, IconDetails } from '@utiligize/shared/resources';

interface Props {
  assetCode?: string;
  containerClassName?: string;
  maxHeight: string;
}

const TableHistory: React.FC<Props> = ({ assetCode, containerClassName = '', maxHeight }) => {
  const { getIntl, dateFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const count = useSelector(historyCountSelector);
  const items = useSelector(historyItemsSelector);
  const portfolioId = useSelector(portfolioIdSelector);
  const versionId = useSelector(simulationVersionIdSelector);
  const isLiveDataConnection = useSelector(isLiveDataConnectionSelector);

  const sendRequest = useCallback(() => {
    if (!portfolioId || !versionId) return Promise.resolve();
    return dispatch(fetchHistoryAction({ assetCode, portfolioId, versionId }));
  }, [assetCode, portfolioId, versionId, dispatch]);

  const renderAssetCode = (item: History.Item) => {
    if (!item.assetUuid || !item.assetCode) return item.assetCode || '-';

    return (
      <Link
        to={{
          pathname: generatePath(Routes.Map, { uuid: item.assetUuid }),
          state: { prevRoute: window.location.pathname },
        }}
      >
        {item.assetCode}
      </Link>
    );
  };

  return (
    <DataTable
      paginationType={PaginationType.HISTORY}
      totalAmount={count}
      sendRequest={sendRequest}
      containerClassName={containerClassName}
      maxHeight={maxHeight}
      triggerTableUpdateDeps={[portfolioId, versionId]}
    >
      {items.map((item: History.Item) => {
        const showMetadataDetails = (item.type as any) === 'Metadata' && !isLiveDataConnection;
        return (
          <tr key={item.id}>
            <td>{item.formName || (item.action && getIntl(item.action)) || '-'}</td>
            <td>{dateFormat(item.createdAt)}</td>
            <td>
              {getIntl(item.type)}
              {showMetadataDetails && (
                <ChecklistStatus className="ml-2" id={item.id} isChecklistDone={item.isChecklistDone} />
              )}
            </td>
            <td>{getIntl(item.assetCategoryName)}</td>
            <td>{renderAssetCode(item)}</td>
            <td>{item.userName}</td>
            <td>
              {item.completedTaskIds?.map((id: number, index: number) => (
                <React.Fragment key={id}>
                  <Link to={generatePath(Routes.Tasks, { id })}>{id}</Link>
                  {item.completedTaskIds.length - 1 !== index ? ', ' : ''}
                </React.Fragment>
              )) || '-'}
            </td>
            <td className="text-right">
              {item.replaceHistory && (
                <OverlayTrigger
                  placement="left"
                  trigger={['hover', 'hover']}
                  rootClose
                  overlay={
                    <Popover id={String(item.id)} style={{ width: '300px' }}>
                      <Popover.Title as="h4">{getIntl('Replace history')}</Popover.Title>
                      <Popover.Content>
                        <ListGroup variant="flush">
                          <ListGroup.Item className="d-flex justify-content-between align-items-center py-2 px-0">
                            {getIntl('Installation date')}: <span>{dateFormat(item.replaceHistory.idriftsatdato)}</span>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex justify-content-between align-items-center py-2 px-0">
                            {getIntl('Type')}: <span>{item.replaceHistory.type}</span>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex justify-content-between align-items-center py-2 px-0">
                            {getIntl('Manufacturer')}: <span>{item.replaceHistory.fabrikat}</span>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex justify-content-between align-items-center py-2 px-0">
                            {getIntl('Serial number')}: <span>{item.replaceHistory.fabrikat_nummer || '-'}</span>
                          </ListGroup.Item>
                        </ListGroup>
                      </Popover.Content>
                    </Popover>
                  }
                >
                  <Button icon={<IconReplace />} data-id={item.id} size="small" />
                </OverlayTrigger>
              )}
              {item.answerId && (
                <Button
                  marginLeft={Boolean(item.replaceHistory)}
                  tooltipKey="Answer details"
                  icon={<IconAnswer />}
                  data-id={item.id}
                  linkProps={{
                    to: {
                      pathname: generatePath(Routes.HistoryAnswers, { id: item.answerId! }),
                      state: { prevRoute: window.location.pathname },
                    },
                  }}
                  size="small"
                />
              )}
              {showMetadataDetails && (
                <Button
                  marginLeft={Boolean(item.answerId || item.replaceHistory)}
                  tooltipKey="Details"
                  icon={<IconDetails />}
                  linkProps={{
                    to: {
                      pathname: generatePath(Routes.History, { id: item.id }),
                      state: { prevRoute: window.location.pathname },
                    },
                  }}
                  size="small"
                />
              )}
              {!item.replaceHistory && !item.answerId && !showMetadataDetails && '-'}
            </td>
          </tr>
        );
      })}
    </DataTable>
  );
};

export default TableHistory;
