import Highcharts from 'highcharts';
import styled from 'styled-components';
import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useWindowSize } from 'hooks';
import { fetchCurrentUserInfoAction } from 'modules/app';
import {
  appCurrentUserFetchedSelector,
  appCurrentUserPermissionsSelector,
  appIsAdminUserSelector,
  appErrorSelector,
} from 'modules/app/selectors';
import { isSidebarOpenSelector, isSidebarHelpMenuOpenSelector } from 'modules/layouts/selectors';
import {
  PageBuilder,
  PageForms,
  PageHistory,
  PageManuals,
  PageInstructions,
  PageTasks,
  PageAssetModels,
  PageInspections,
  PageMeters,
  PageMap,
  PageNetworkLoading,
  PageInvestment,
  PageMaintenance,
  PageAdmin,
  PageCustomers,
  PageAppExceptions,
  PageNewLoad,
  PageAnalysisTool,
  PageDataQuality,
  PageSetup,
  PageN1,
  // Reinvestment
  PageReliability,
  PageMonetizedRisk,
  PageFeatureToggles,
  PageCO2e,
} from 'pages';
import { Navigation, Sidebar, SidebarHelpMenu } from 'components';
import { ConfirmationModal, TableSettingsModal, ModalReleaseNotification } from 'components/_modals';
import { Spinner } from 'components/_common';
import { isNKTenant, setStorageItem } from 'utils';
import { AppErrorsTypes, Routes, StorageKeys, isProduction } from 'constants/index';
import PageAssets from './PageAssets';

const PageMain: React.FC<RouteComponentProps> = ({ location }) => {
  const history = useHistory();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const isMobile = useWindowSize();

  const isSidebarOpen = useSelector(isSidebarOpenSelector);
  const appCurrentUserFetched = useSelector(appCurrentUserFetchedSelector);
  const appIsAdminUser = useSelector(appIsAdminUserSelector);
  const appCurrentUserPermissions = useSelector(appCurrentUserPermissionsSelector);
  const isSidebarHelpMenuOpen = useSelector(isSidebarHelpMenuOpenSelector);
  const appError = useSelector(appErrorSelector);
  const isGlobalAppDataLoading = Boolean(appCurrentUserFetched === null);

  useEffect(() => {
    // Automatic high charts reflow trigger
    setTimeout(() => Highcharts.charts.forEach(c => c?.reflow()), 500);
  }, [isSidebarOpen]);

  useEffect(() => {
    // Update last route on routing
    setStorageItem({ [StorageKeys.LAST_ROUTE]: `${location.pathname}${location.hash}` });
  }, [location.pathname, location.hash]);

  useEffect(() => {
    dispatch(fetchCurrentUserInfoAction());
  }, [dispatch]);

  const renderContent = () => {
    if (isGlobalAppDataLoading) return <Spinner isInFullHeightContainer />;
    const skipNoScenariosError =
      appError === AppErrorsTypes.NO_SCENARIOS && [Routes.Setup].includes(history.location.pathname);
    const skipNoSimulationsError =
      appError === AppErrorsTypes.NO_SIMULATIONS &&
      [
        Routes.Setup,
        Routes.SetupInvestment,
        Routes.SetupInvestmentLimitations,
        Routes.SetupInvestmentComponentPriceScenarios,
        Routes.SetupInvestmentComponentPrices,
        Routes.SetupInvestmentWorkPrices,
        Routes.SetupInvestmentBudget,
        Routes.SetupCO2e,
        Routes.SetupRawData,
        Routes.SetupCalculationSchedule,
        Routes.NewLoad,
        Routes.NewLoadCategories,
        Routes.NewLoadConnectionManager,
        Routes.NewLoadProfiles,
        Routes.NewLoadPhaseIn,
        Routes.NewLoadGlobalDevelopment,
      ].includes(history.location.pathname);
    if (appError && !skipNoScenariosError && !skipNoSimulationsError) {
      return <PageAppExceptions appError={appError} />;
    }

    return (
      <Switch>
        {appIsAdminUser && <Route path={Routes.Admin} component={PageAdmin} />}
        <Route path={Routes.FeatureToggles} component={PageFeatureToggles} />

        {/* GENERAL */}
        <Route exact path={Routes.Map} component={PageMap} />
        <Route path={Routes.CO2e} component={PageCO2e} />

        {/* Forecast & Investment */}
        <Route path={Routes.NetworkLoading} component={PageNetworkLoading} />
        <Route path={Routes.N1} component={PageN1} />
        <Route path={Routes.Customers} component={PageCustomers} />
        <Route path={Routes.Investment} component={PageInvestment} />
        <Route path={Routes.DataQuality} component={PageDataQuality} />
        <Route path={Routes.Setup} component={PageSetup} />
        <Route path={Routes.NewLoad} component={PageNewLoad} />
        <Route path={Routes.AnalysisTool} component={PageAnalysisTool} />

        {/* Maintenance & planning */}
        <Route path={Routes.Tasks} component={PageTasks} />
        <Route path={Routes.Meters} component={PageMeters} exact />
        <Route path={Routes.Maintenance} component={PageMaintenance} />
        <Route path={Routes.AssetModels} component={PageAssetModels} />
        <Route path={Routes.Forms} component={PageForms} exact />
        <Route
          exact
          path={Routes.FormBuilder}
          render={(props: RouteComponentProps) => <PageBuilder {...props} isFormBuilder />}
        />
        <Route exact path={Routes.Instructions} component={PageInstructions} />
        <Route
          exact
          path={Routes.InstructionBuilder}
          render={(props: RouteComponentProps) => <PageBuilder {...props} isInstructionBuilder />}
        />
        <Route exact path={Routes.Inspections} component={PageInspections} />
        <Route
          exact
          path={Routes.InspectionBuilder}
          render={(props: RouteComponentProps) => <PageBuilder {...props} isToolInspectionBuilder />}
        />
        <Route path={Routes.History} component={PageHistory} />
        <Route exact path={Routes.DataManuals} component={PageManuals} />

        {/* Reinvestment */}
        {isNKTenant && <Route path={Routes.Assets} component={PageAssets} />}
        <Route path={Routes.Reliability} component={PageReliability} />
        <Route exact path={Routes.MonetizedRisk} component={PageMonetizedRisk} />

        <Route path="*" component={PageAppExceptions} />
      </Switch>
    );
  };

  return (
    <StyledLayout
      className={classNames('sidebar-mini', 'layout-fixed', {
        'sidebar-collapse': !isSidebarOpen,
        'sidebar-open': isSidebarOpen && isMobile,
        'control-sidebar-slide-open': isSidebarHelpMenuOpen,
      })}
    >
      <div className="wrapper overflow-hidden">
        <Navigation appCurrentUserPermissions={appCurrentUserPermissions} />
        <Sidebar isMobile={isMobile} appCurrentUserFetched={!isGlobalAppDataLoading} />
        <StyledContentWrapper className="content-wrapper position-relative" $isSidebarOpen={isSidebarOpen}>
          {renderContent()}
          {!isProduction && isSidebarHelpMenuOpen && <SidebarHelpMenu />}
        </StyledContentWrapper>
        <ConfirmationModal />
        <TableSettingsModal />
        <ModalReleaseNotification />
      </div>
    </StyledLayout>
  );
};

const StyledLayout = styled.div`
  ${props => props.theme.mixins.scroll};
`;

const StyledContentWrapper = styled.div<{ $isSidebarOpen: boolean }>`
  &&&& {
    display: flex;
    flex-direction: column;
    background: ${props => props.theme.colors.grey25};
    min-height: calc(100vh - ${props => props.theme.heights.topNavigation});
    margin-top: ${props => props.theme.heights.topNavigation};

    ${({ $isSidebarOpen }) => !$isSidebarOpen && `margin-left: 60px!important`};
  }
`;

export default PageMain;
