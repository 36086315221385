import styled from 'styled-components';
import React, { memo } from 'react';
import {
  AssetTimeSeriesChart,
  AssetProfileChart,
  AssetDurationChart,
  ChartProbabilityOfFailure,
  EvolutionChart,
} from 'components/_charts';
import ScrollableTabs, { StyledTabScreen } from '../ScrollableTabs';
import TabTasks from './TabTasks';
import CustomerAssetTypeSupportAlert from './CustomerAssetTypeSupportAlert';
import { Spinner } from 'components/_common';
import { StorageKeys, TimeSeriesTypes } from 'constants/index';
import { BottomTabsIndexesMap } from './constants';

interface Props {
  uuid: string;
  cnaim_id: number | null;
  isAssetMetaFetched: boolean;
  isCustomerAssetType: boolean;
}

const BottomPanelTabs: React.FC<Props> = memo(({ uuid, cnaim_id, isAssetMetaFetched, isCustomerAssetType }) => {
  const renderTab = (Component: React.JSX.Element) => {
    if (!isAssetMetaFetched) return <Spinner isInFullHeightContainer />;
    if (isCustomerAssetType) return <CustomerAssetTypeSupportAlert />;
    return Component;
  };
  return (
    <ScrollableTabs
      tabsLabelKeys={['Power', 'Voltage', 'Losses', 'Evolution', 'Profile', 'Duration', 'Reliability', 'Tasks']}
      storageKey={StorageKeys.MAP_PANEL_BOTTOM_TAB_ACTIVE_INDEX}
    >
      {activeTab => (
        <>
          <StyledTabScreen key="Power" activeTab={activeTab} index={BottomTabsIndexesMap.Power} $absolute>
            <AssetTimeSeriesChart uuid={uuid} type={TimeSeriesTypes.POWER} height="100%" isTooltipOutside />
          </StyledTabScreen>
          <StyledTabScreen
            key="Voltage"
            activeTab={activeTab}
            index={BottomTabsIndexesMap.Voltage}
            $absolute
            $paddings={isCustomerAssetType}
          >
            {renderTab(
              <AssetTimeSeriesChart uuid={uuid} type={TimeSeriesTypes.VOLTAGE} height="100%" isTooltipOutside />
            )}
          </StyledTabScreen>
          <StyledTabScreen
            key="Losses"
            activeTab={activeTab}
            index={BottomTabsIndexesMap.Losses}
            $absolute
            $paddings={isCustomerAssetType}
          >
            {renderTab(
              <AssetTimeSeriesChart uuid={uuid} type={TimeSeriesTypes.LOSSES} height="100%" isTooltipOutside />
            )}
          </StyledTabScreen>
          <StyledTabScreen
            key="Evolution"
            activeTab={activeTab}
            index={BottomTabsIndexesMap.Evolution}
            $absolute
            $paddings={isCustomerAssetType}
          >
            {renderTab(<EvolutionChart uuid={uuid} height="100%" isTooltipOutside />)}
          </StyledTabScreen>
          <StyledTabScreen
            key="Profile"
            activeTab={activeTab}
            index={BottomTabsIndexesMap.Profile}
            $absolute
            $paddings={isCustomerAssetType}
          >
            {renderTab(<AssetProfileChart uuid={uuid} height="100%" isTooltipOutside />)}
          </StyledTabScreen>
          <StyledTabScreen
            key="Duration"
            activeTab={activeTab}
            index={BottomTabsIndexesMap.Duration}
            $absolute
            $paddings={isCustomerAssetType}
          >
            {renderTab(<AssetDurationChart uuid={uuid} height="100%" isTooltipOutside />)}
          </StyledTabScreen>
          <StyledTabScreen key="Reliability" activeTab={activeTab} index={BottomTabsIndexesMap.Reliability} $absolute>
            {renderTab(<StyledProbabilityOfFailureChart uuid={uuid} cnaim_id={cnaim_id} />)}
          </StyledTabScreen>
          <StyledTabScreen key="Tasks" activeTab={activeTab} index={BottomTabsIndexesMap.Tasks}>
            <TabTasks uuid={uuid} />
          </StyledTabScreen>
        </>
      )}
    </ScrollableTabs>
  );
});

const StyledProbabilityOfFailureChart = styled(ChartProbabilityOfFailure)`
  height: 100%;
  width: 100%;
`;

export default BottomPanelTabs;
