import React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router';
import { Assets, AssetsSubComponents, AssetDetails } from 'components/Assets';
import PageAppExceptions from './PageAppExceptions';
import { Routes } from 'constants/index';

const PageAssets: React.FC = () => (
  <Switch>
    <Route exact path={Routes.Assets} component={Assets} />
    <Route exact path={Routes.AssetsSubComponents} component={AssetsSubComponents} />
    <Route
      exact
      path={Routes.AssetCreate}
      render={(props: RouteComponentProps) => <AssetDetails isCreateMode {...props} />}
    />
    <Route exact path={Routes.AssetDetails} component={AssetDetails} />
    <Route path="*" component={PageAppExceptions} />
  </Switch>
);

export default PageAssets;
