import styled from 'styled-components';
import React, { useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocale } from 'hooks';
import { onlyModalToastsSelector } from 'modules/layouts/selectors';
import { deleteToastAction } from 'modules/layouts';
import SharedModal, { ModalProps } from '@utiligize/shared/components/Modal';
import { Alert } from 'components/_common';

const ModalError: React.FC<Shared.Toast> = memo(({ id, message }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  useEffect(() => {
    document
      .querySelector('.modal-content')!
      .addEventListener('click', () => dispatch(deleteToastAction(id)), { passive: true, once: true });
  }, [id, dispatch]);

  return (
    <StyledAlert key={id} variant="danger" className="mt-3">
      {message}
    </StyledAlert>
  );
});

const StyledAlert = styled(Alert)`
  text-align: center;
  font-size: 13.5px;
  margin-bottom: 0;
`;

const Modal: React.FC<ModalProps> = ({ children, ...props }) => {
  const { getIntl } = useLocale();
  const onlyModalToasts = useSelector(onlyModalToastsSelector);

  const renderChildren = () => {
    if (!children) return undefined;
    return (
      <>
        {children}
        {Boolean(onlyModalToasts?.length) && onlyModalToasts.map(toast => <ModalError key={toast.id} {...toast} />)}
      </>
    );
  };

  return (
    <SharedModal {...props} getIntl={getIntl}>
      {renderChildren()}
    </SharedModal>
  );
};

export default Modal;
