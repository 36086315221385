import React from 'react';
import { useSelector } from 'react-redux';
import { useLocale } from 'hooks';
import { portfolioOptionSelector } from 'modules/options/selectors';
import { Alert } from 'components/_common';

const PortfolioInfo: React.FC = () => {
  const { getIntl } = useLocale();
  const portfolioOption = useSelector(portfolioOptionSelector);

  if (!portfolioOption) return null;
  return (
    <Alert variant="light" className="mb-0">
      <small>
        {getIntl('This scenario will apply to the {{portfolio}} portfolio', {
          portfolio: portfolioOption?.label,
        })}
        .
      </small>
    </Alert>
  );
};

export default PortfolioInfo;
