import React, { useContext, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { MapContext } from 'context/map';
import { mapStateThemeSelector } from 'modules/map/selectors';
import { MapThemes } from 'constants/index';
import Other from 'components/Map/core/themes/Other';

const Assets = React.lazy(() => import('components/Map/core/themes/Assets'));
const MaxLoad = React.lazy(() => import('components/Map/core/themes/MaxLoad'));
const DataQuality = React.lazy(() => import('components/Map/core/themes/DataQuality'));
const Consumption = React.lazy(() => import('components/Map/core/themes/Consumption'));
const Losses = React.lazy(() => import('components/Map/core/themes/Losses'));
const N1 = React.lazy(() => import('components/Map/core/themes/N1'));
const N1MaxLoad = React.lazy(() => import('components/Map/core/themes/N1MaxLoad'));
const Replacements = React.lazy(() => import('components/Map/core/themes/Replacements'));
const TasksSetupLayers = React.lazy(() => import('components/Map/_tasks/TasksSetupLayers'));

const SetupThemes: React.FC = () => {
  const { map } = useContext(MapContext);
  const theme = useSelector(mapStateThemeSelector);

  const renderTheme = () => {
    switch (theme) {
      case MapThemes.YEARLY_MAX_LOAD:
        return <MaxLoad map={map!} />;
      case MapThemes.DATA_QUALITY:
        return <DataQuality map={map!} />;
      case MapThemes.YEARLY_CONSUMPTION:
        return <Consumption map={map!} />;
      case MapThemes.YEARLY_LOSSES:
        return <Losses map={map!} />;
      case MapThemes.N1_ROUTES:
        return <N1 map={map!} />;
      case MapThemes.N1_YEARLY_MAX_LOAD:
        return <N1MaxLoad map={map!} />;
      case MapThemes.YEARLY_REPLACEMENTS:
        return <Replacements map={map!} />;
      case MapThemes.TASK:
        return <TasksSetupLayers map={map!} />;
      default:
        return <Assets map={map!} />;
    }
  };

  if (!map) return null;
  return (
    <>
      <Suspense>{renderTheme()}</Suspense>
      <Other />
    </>
  );
};

export default SetupThemes;
