import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
export const appRootSelector = (state: State.Root) => state.app;

export const appLangSelector = createSelector(appRootSelector, app => app.lang);

export const userNameSurnameEmailStringSelector = createSelector(
  appRootSelector,
  app => `${app.firstName} ${app.lastName} (${app.email})`
);

export const appCurrentUserIdSelector = createSelector(appRootSelector, app => app.id);

export const appCurrentUserFetchedSelector = createSelector(appRootSelector, app => app.appCurrentUserFetched);

export const appSidebarHelpMenuPageSelectorFactory = (pageId: number | null) =>
  createSelector(
    appRootSelector,
    (app): App.SidebarHelpMenuPage | null => (pageId && app.sidebarHelpMenuPagesHash?.[pageId]) || null
  );

export const appIsAdminUserSelector = createSelector(appRootSelector, app => app.isAdmin);

export const appIsSupervisorUserSelector = createSelector(appRootSelector, app => app.isSupervisor);

export const externalTimeSystemSelector = createSelector(appRootSelector, app => app.externalTimeSystem);

export const isLiveDataConnectionSelector = createSelector(appRootSelector, app => app.isLiveDataConnection);

export const appCurrentUserPermissionsSelector = createSelector(appRootSelector, (app): Permissions.Permissions => {
  return {
    ...app.groupPermissions.reduce(
      (acc, group) => {
        Object.keys(group.permissionsTypes).forEach((key: string) => {
          if (group.permissionsTypes[key as Permissions.PermissionsTypes]) {
            acc[key as Permissions.PermissionsTypes] = group.permissionsTypes[key as Permissions.PermissionsTypes];
          }
        });
        return acc;
      },
      { ...app.userPermissions }
    ),
  };
});

export const isMaintenancePlanningEnabledSelector = createSelector(
  appCurrentUserPermissionsSelector,
  (appCurrentUserPermissions: Permissions.Permissions) =>
    appCurrentUserPermissions.isTasksEnabled ||
    appCurrentUserPermissions.isSmartMetersEnabled ||
    appCurrentUserPermissions.isMaintenanceEnabled ||
    appCurrentUserPermissions.isAssetModelsEnabled ||
    appCurrentUserPermissions.isFormsEnabled ||
    appCurrentUserPermissions.isHistoryEnabled ||
    appCurrentUserPermissions.isDataManualsEnabled
);

export const isSingleLineDiagramEnabledSelector = createSelector(
  appCurrentUserPermissionsSelector,
  (appCurrentUserPermissions: Permissions.Permissions) =>
    appCurrentUserPermissions.isMapEnabled && appCurrentUserPermissions.isSingleLineDiagramEnabled
);

export const isN1EnabledSelector = createSelector(
  appCurrentUserPermissionsSelector,
  (appCurrentUserPermissions: Permissions.Permissions) => appCurrentUserPermissions.isN1Available
);

export const isMaterialsEnabledSelector = createSelector(
  appCurrentUserPermissionsSelector,
  (appCurrentUserPermissions: Permissions.Permissions) => appCurrentUserPermissions.isMaterialsEnabled
);

export const appErrorSelector = createSelector(appRootSelector, app => app.error);

export const selectedTimezoneSelector = createSelector(appRootSelector, app => app.selectedTimezone);

export const currentAppReleaseVersionSelector = createSelector(appRootSelector, app => app.currentAppReleaseVersion);
