import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { manualsItemsSelector, manualsCountSelector } from 'modules/manuals/selectors';
import { fetchManualsAction, deleteManualByIdAction } from 'modules/manuals';
import { setModalConfirmationMetaAction, setLayoutAction } from 'modules/layouts';
import { DataTable, Button } from 'components/_common';
import { PaginationType } from 'constants/index';
import { IconEdit, IconDelete } from '@utiligize/shared/resources';

interface Props {
  assetCode?: string;
  maxHeight: string;
}

const ManualsTable: React.FC<Props> = ({ assetCode, maxHeight }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl, dateFormat } = useLocale();

  const count = useSelector(manualsCountSelector);
  const items = useSelector(manualsItemsSelector);

  const sendRequest = useCallback(() => dispatch(fetchManualsAction({ assetCode })), [dispatch, assetCode]);

  const handleEditBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: number | null = Number(event.currentTarget.getAttribute('data-id'));
      if (id) dispatch(setLayoutAction({ manualsModalId: id }));
    },
    [dispatch]
  );

  const handleDeleteBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: number = Number(event.currentTarget.getAttribute('data-id'));
      dispatch(setModalConfirmationMetaAction({ onConfirm: () => dispatch(deleteManualByIdAction(id)) }));
    },
    [dispatch]
  );

  return (
    <DataTable
      hideFilters={Boolean(assetCode)}
      paginationType={PaginationType.MANUALS}
      totalAmount={count}
      sendRequest={sendRequest}
      maxHeight={maxHeight}
    >
      {items?.map((item: Manuals.Item) => (
        <tr key={item.id}>
          <td>{item.name}</td>
          <td>{dateFormat(item.createdAt)}</td>
          <td>
            {[...new Set([item.categoryName, ...item.assignedModels.map(i => i.assetcategoryName)].filter(Boolean))]
              .map((name: any) => getIntl(name))
              .join(', ') || '-'}
          </td>
          <td>{item.assetCode || '-'}</td>
          <td className="text-right">
            <Button tooltipKey="Edit" icon={<IconEdit />} data-id={item.id} onClick={handleEditBtnClick} size="small" />
            <Button
              marginLeft
              tooltipKey="Delete"
              icon={<IconDelete />}
              data-id={item.id}
              onClick={handleDeleteBtnClick}
              size="small"
            />
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default ManualsTable;
