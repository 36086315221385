import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useLocale, usePaginationHandlers } from 'hooks';
import { ScenarioRunInfo } from 'components';
import {
  Checkbox,
  ContentCard,
  ContentContainer,
  DataTableTemplate,
  SelectPrimarySubstations,
  SelectScenarioYears,
} from 'components/_common';
import SelectPercentile from './SelectPercentile';
import { PaginationType } from 'constants/index';
import { ButtonSwitch } from '@utiligize/shared/components';
import ChartVoltageDrop from './ChartVoltageDrop';

const ViewVoltageDrop: React.FC = () => {
  const { getIntl } = useLocale();
  const type = PaginationType.NETWORK_LOADING_VOLTAGE_DROP;
  const { filters, handleSelectChange, handleCheckboxClick, handleSwitchClick } = usePaginationHandlers({
    type,
  });

  return (
    <DataTableTemplate paddingBottom>
      <Row>
        <Col sm={9}>
          <Row style={{ rowGap: '0.5rem' }}>
            <Col xs={4}>
              <SelectPrimarySubstations
                name="primarySubstationsId"
                value={filters?.primarySubstationsId ? [filters?.primarySubstationsId] : null}
                onChange={handleSelectChange}
              />
            </Col>
            <Col xs={4}>
              <SelectScenarioYears
                name="year"
                value={filters?.year as number}
                onChange={handleSelectChange}
                labelKey=""
                mutedTextLabelKey="Scenarios year"
                variant="small"
              />
            </Col>
            <Col xs={4}>
              <SelectPercentile
                name="percentile"
                labelKey=""
                value={filters?.percentile || null}
                onChange={handleSelectChange}
                isSearchable={false}
                variant="small"
                mutedTextLabelKey="Percentile"
              />
            </Col>
            <Col xs="auto" className="d-flex align-items-center">
              <ButtonSwitch
                checked={!filters?.min_or_max}
                width={160}
                offlabel={getIntl('Min')}
                onlabel={getIntl('Max')}
                onChange={value => handleSwitchClick('min_or_max', !value)}
              />
            </Col>
            <Col xs="auto">
              <Checkbox
                labelKey="Investment"
                className="icheck-primary"
                name="investment"
                checked={filters?.investment}
                onChange={handleCheckboxClick}
              />
            </Col>
            <Col xs="auto">
              <Checkbox
                labelKey="Flex"
                className="icheck-primary"
                name="flex"
                checked={filters?.flex}
                onChange={handleCheckboxClick}
              />
            </Col>
          </Row>
        </Col>
        <Col sm={3} className="d-flex flex-column align-items-end">
          <ScenarioRunInfo />
        </Col>
      </Row>
      <ContentContainer>
        <ContentCard>
          <ChartVoltageDrop />
        </ContentCard>
      </ContentContainer>
    </DataTableTemplate>
  );
};

export default ViewVoltageDrop;
