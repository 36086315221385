import React, { useCallback } from 'react';
import { useLocale } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import { subComponentsSelector } from 'modules/assets/selectors';
import { fetchSubComponentsAction, deleteSubComponentAction } from 'modules/assets';
import { setLayoutAction, setModalConfirmationMetaAction, setInfoToastAction } from 'modules/layouts';
import { DataTable, Button } from 'components/_common';
import { PaginationType, AssetCategories } from 'constants/index';
import { IconEdit, IconDelete } from '@utiligize/shared/resources';

const SubComponentsTypesTable: React.FC<{ assetcategoryCode: AssetCategories | undefined }> = ({
  assetcategoryCode,
}) => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const items = useSelector(subComponentsSelector);
  const count = Number(items?.length);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchSubComponentsAction(params)).then(
        (action: Shared.ReduxAction<{ subComponentsHash: Type.Hash<Asset.SubComponentType> }>) =>
          Object.values(action.payload?.subComponentsHash || {})
      ),
    [dispatch]
  );

  const handleEditBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      if (assetcategoryCode !== AssetCategories.BD) {
        dispatch(setInfoToastAction('You can edit or delete only Buildings sub-components'));
        return;
      }
      const id: string | null = event.currentTarget.getAttribute('data-id');
      dispatch(setLayoutAction({ assetSubComponentModalId: Number(id) }));
    },
    [dispatch, assetcategoryCode]
  );

  const handleDeleteBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      if (assetcategoryCode !== AssetCategories.BD) {
        dispatch(setInfoToastAction('You can edit or delete only Buildings sub-components'));
        return;
      }
      const id: number = Number(event.currentTarget.getAttribute('data-id'));
      if (!id) return;
      dispatch(
        setModalConfirmationMetaAction({ onConfirm: () => dispatch(deleteSubComponentAction(id, assetcategoryCode)) })
      );
    },
    [dispatch, assetcategoryCode]
  );

  return (
    <DataTable
      paginationType={PaginationType.SUB_COMPONENTS}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
      maxHeight="calc(100vh - 218px)"
    >
      {(items || [])?.map((item: Asset.SubComponentType) => (
        <tr key={item.id}>
          <td>{getIntl(item.name)}</td>
          <td className="text-right">
            <Button tooltipKey="Edit" icon={<IconEdit />} data-id={item.id} onClick={handleEditBtnClick} size="small" />
            <Button
              marginLeft
              tooltipKey="Delete"
              icon={<IconDelete />}
              data-id={item.id}
              onClick={handleDeleteBtnClick}
              size="small"
            />
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default SubComponentsTypesTable;
