import React, { useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { generatePath } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { setPaginationAction } from 'modules/layouts';
import AssetsTable from './AssetsTable';
import { DataTableTemplate, SelectAssetCategories, Button } from 'components/_common';
import { PaginationType, Routes } from 'constants/index';
import { IconPlus } from '@utiligize/shared/resources';

const Assets: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(PaginationType.ASSETS));

  const handleChangeCategorySelect = useCallback(
    (value: any) => {
      dispatch(
        setPaginationAction({
          type: PaginationType.ASSETS,
          modifier: { filters: { ...filters, assetcategoryCode: value.value }, offset: 0 },
        })
      );
    },
    [dispatch, filters]
  );

  return (
    <DataTableTemplate paddingBottom>
      <Row>
        <Col sm={3}>
          <SelectAssetCategories
            labelKey=""
            value={filters?.assetcategoryCode || ''}
            onChange={handleChangeCategorySelect}
            isDisabled
            variant="small"
          />
        </Col>
        <Col>
          <Button
            linkProps={{ to: generatePath(Routes.AssetCreate) }}
            icon={<IconPlus />}
            labelKey="Create asset"
            variant="primary"
          />
        </Col>
      </Row>
      <AssetsTable />
    </DataTableTemplate>
  );
};

export default Assets;
