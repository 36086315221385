import styled from 'styled-components';
import React, { useCallback, useState, useEffect } from 'react';
import classNames from 'classnames';
import { Form, Row, Col, ListGroup } from 'react-bootstrap';
import { useLocale, useEventPreventedExec } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import { tasksChecklistsModalIdSelector } from 'modules/layouts/selectors';
import { setLayoutAction } from 'modules/layouts';
import { checklistSelectorFactory } from 'modules/tasks/selectors';
import {
  Modal,
  Button,
  FormInput,
  SelectAssetCategories,
  HiddenFormSubmit,
  SelectTaskAppAction,
} from 'components/_common';
import { NonAssetTaskAppActions, TaskAppActions } from 'constants/index';
import useConfiguredFormik from './useConfiguredFormik';
import { IconDelete, IconPlus } from '@utiligize/shared/resources';

const ModalChecklists: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();
  const id: number | null = useSelector(tasksChecklistsModalIdSelector);
  const checklist: Tasks.Checklist | undefined = useSelector(checklistSelectorFactory(id));
  const isEditMode: boolean = Boolean(id);
  const [show, setShow] = useState(false);

  const toggleModal = useCallback((): void => {
    // Reset redux layouts state for isEditMode
    if (show && id) dispatch(setLayoutAction({ tasksChecklistsModalId: null }));
    setShow(!show);
  }, [id, show, dispatch]);

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    submitForm,
    setFieldValue,
    setValues,
    setFieldTouched,
    resetForm,
  } = useConfiguredFormik({ id, toggleModal });
  const handleFormSubmit = useEventPreventedExec(submitForm);

  useEffect(() => {
    if (!show) resetForm();
  }, [show, resetForm]);

  useEffect(() => {
    if (!checklist) return;
    setValues({
      name: checklist.name,
      appAction: checklist.appAction,
      assetCategoryCode: checklist.assetCategoryCode,
      questionInput: '',
      questions: checklist.questions,
    });
    setShow(true);
  }, [checklist, setValues]);

  const handleAddButtonClick = useCallback(() => {
    setFieldValue('questionInput', '');
    setFieldValue('questions', values.questions.concat(values.questionInput));
  }, [values.questions, values.questionInput, setFieldValue]);

  const handleDeleteButtonClick = useCallback(
    (event: React.SyntheticEvent) => {
      const question: string = event.currentTarget.getAttribute('data-question') as string;
      setFieldValue(
        'questions',
        values.questions.filter(q => q !== question)
      );
    },
    [values.questions, setFieldValue]
  );

  return (
    <>
      <Button icon={<IconPlus />} labelKey="Create checklist" onClick={toggleModal} variant="primary" />
      <Modal
        show={show}
        onHide={toggleModal}
        titleKey={isEditMode ? 'Edit checklist' : 'Create checklist'}
        cancelButtonProps={{
          disabled: isSubmitting,
          onClick: toggleModal,
        }}
        submitButtonProps={{
          labelKey: isEditMode ? 'Update' : 'Create',
          loading: isSubmitting,
          onClick: handleFormSubmit,
        }}
      >
        <Form onSubmit={handleFormSubmit}>
          <Form.Group>
            <FormInput
              autoFocus
              labelKey="Name"
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              errorKey={Boolean(touched.name && errors.name) ? (errors.name as string) : ''}
              isNew
            />
          </Form.Group>
          <Form.Group>
            <SelectTaskAppAction
              actions={values.appAction}
              onChange={(v: any) => setValues({ ...values, appAction: v.value, assetCategoryCode: null })}
              onBlur={() => setFieldTouched('appAction')}
              errorKey={Boolean(touched.appAction && errors.appAction) ? (errors.appAction as string) : ''}
              excludedOptions={[TaskAppActions.ToolInspection]}
              variant="small"
            />
          </Form.Group>
          <Form.Group>
            <SelectAssetCategories
              value={values.assetCategoryCode}
              onChange={(v: any) => setFieldValue('assetCategoryCode', v.value)}
              onBlur={() => setFieldTouched('assetCategoryCode')}
              errorKey={
                Boolean(touched.assetCategoryCode && errors.assetCategoryCode)
                  ? (errors.assetCategoryCode as string)
                  : ''
              }
              excludeSyntheticOptions
              isDisabled={!values.appAction || NonAssetTaskAppActions.includes(values.appAction)}
              variant="small"
            />
          </Form.Group>
          <StyledQuestionInputContainer
            className={classNames({ 'is-invalid': Boolean(touched.questions && errors.questions) })}
          >
            <div>
              <FormInput
                labelKey="Add question"
                name="questionInput"
                value={values.questionInput}
                onChange={handleChange}
                onBlur={handleBlur}
                isNew
              />
            </div>
            <Button icon={<IconPlus />} disabled={!values.questionInput} onClick={handleAddButtonClick} marginLeft />
          </StyledQuestionInputContainer>
          <Form.Control.Feedback type="invalid">{getIntl(errors.questions as string)}</Form.Control.Feedback>
          <StyledListGroup>
            {values.questions.map((question: string) => (
              <ListGroup.Item key={question}>
                <Row className="align-items-center">
                  <Col>{question}</Col>
                  <Col xs="auto">
                    <Button
                      icon={<IconDelete />}
                      data-question={question}
                      onClick={handleDeleteButtonClick}
                      size="small"
                    />
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
          </StyledListGroup>
          <HiddenFormSubmit />
        </Form>
      </Modal>
    </>
  );
};

const StyledQuestionInputContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;

  > div:first-child {
    flex-grow: 1;
  }
`;

const StyledListGroup = styled(ListGroup)`
  margin-top: 0.5rem;
  border-radius: 8px;
  max-height: 260px;
  overflow-x: hidden;
  ${props => props.theme.mixins.scroll};

  > div {
    padding: 5px 10px;
  }
`;

export default ModalChecklists;
