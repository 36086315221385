import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { customersTypesSelector } from 'modules/networkLoading/selectors';
import { fetchAssetCustomersAction } from 'modules/networkLoading';
import { portfolioIdSelector, scenarioIdSelector } from 'modules/layouts/selectors';
import { selectedAndExistChartYearSelector } from 'modules/options/selectors';
import { DataTable } from 'components/_common';
import { PaginationType } from 'constants/index';

const TableCustomers: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);
  const year = useSelector(selectedAndExistChartYearSelector);

  const items = useSelector(customersTypesSelector);
  const count = items.length;

  const sendRequest = useCallback(
    () => dispatch(fetchAssetCustomersAction({ portfolioId, scenarioId, year })),
    [dispatch, portfolioId, scenarioId, year]
  );

  return (
    <DataTable
      paginationType={PaginationType.NETWORK_LOADING_CUSTOMERS_TYPES}
      totalAmount={count}
      sendRequest={sendRequest}
      containerClassName="p-0"
      waitForDependencies={!portfolioId || !scenarioId || !year}
      triggerTableUpdateDeps={[portfolioId, scenarioId, year]}
    >
      {items?.map((item: NetworkLoading.CustomersTypes) => (
        <tr key={item.id}>
          <td>{item.Type}</td>
          <td>{item.Number}</td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableCustomers;
