import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import {
  isPageTasksChecklistsTabActiveSelector,
  isPageTasksMaterialsTabActiveSelector,
} from 'modules/router/selectors';
import { tasksActiveTypeSelector } from 'modules/tasks/selectors';
import { TasksModal } from 'components/_modals';
import ModalChecklists from './ModalChecklists';
import ModalMaterials from './ModalMaterials';
import ModalUploadMaterials from './ModalUploadMaterials';
import { DataTableTemplate, DataTableHeaderFilters } from 'components/_common';
import { DataTableFilterKeys } from 'constants/index';
import TasksTabs from './TasksTabs';

const ViewTasks: React.FC = () => {
  const isPageTasksChecklistsTabActive = useSelector(isPageTasksChecklistsTabActiveSelector);
  const isPageTasksMaterialsTabActive = useSelector(isPageTasksMaterialsTabActiveSelector);
  const paginationType = useSelector(tasksActiveTypeSelector);

  const renderModals = () => {
    if (isPageTasksChecklistsTabActive) {
      return (
        <Col>
          <ModalChecklists />
        </Col>
      );
    }
    if (isPageTasksMaterialsTabActive) {
      return (
        <>
          <Col xs="auto">
            <ModalMaterials />
          </Col>
          <Col xs="auto">
            <ModalUploadMaterials />
          </Col>
        </>
      );
    }
    return (
      <Col>
        <TasksModal />
      </Col>
    );
  };

  return (
    <DataTableTemplate>
      <Row>
        {paginationType && (
          <DataTableHeaderFilters paginationType={paginationType} showKeys={[DataTableFilterKeys.USER_IDS]} />
        )}
        <>{renderModals()}</>
      </Row>
      <TasksTabs />
    </DataTableTemplate>
  );
};

export default ViewTasks;
