import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router';
import { ProgressBar, OverlayTrigger, Popover, ListGroup } from 'react-bootstrap';
import { deleteTaskAction } from 'modules/tasks';
import { useLocale } from 'hooks';
import { setLayoutAction } from 'modules/layouts';
import { Button, TaskStatusBadge, IconDeleteTableAction } from 'components/_common';
import { Routes, TaskStatusVariantMap, TaskStatus } from 'constants/index';
import { IconEdit, IconDetails } from '@utiligize/shared/resources';

const TaskOverlay: React.FC<Tasks.Task> = ({
  id,
  name,
  type,
  appAction,
  totalExpectedTime,
  registeredTime,
  status,
}) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();
  const taskCompletionProgress: number = Math.round((registeredTime / totalExpectedTime) * 100);

  const handleEditBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: number | null = Number(event.currentTarget.getAttribute('data-id'));
      if (!id) return;
      dispatch(setLayoutAction({ taskModalMeta: { id } }));
      document.body.click(); // close popover
    },
    [dispatch]
  );

  return (
    <OverlayTrigger
      trigger="click"
      rootClose
      overlay={
        <Popover id={String(id)}>
          <Popover.Title as="h4">{getIntl('Task details')}</Popover.Title>
          <Popover.Content style={{ minWidth: '250px' }}>
            <ListGroup variant="flush">
              <ListGroup.Item className="d-flex justify-content-between align-items-center py-2 px-0">
                {getIntl('Type')}: <span>{type ? getIntl(type) : '-'}</span>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center py-2 px-0">
                {getIntl('App action')}: <span>{appAction ? getIntl(appAction) : '-'}</span>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center py-2 px-0">
                {getIntl('Total expected time')}:{' '}
                <span>{`${totalExpectedTime} ${getIntl(totalExpectedTime <= 1 ? 'Hour' : 'Hours')}`}</span>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center py-2 px-0">
                {getIntl('Registered time')}:{' '}
                <span>{`${registeredTime} ${getIntl(registeredTime <= 1 ? 'Hour' : 'Hours')}`}</span>
              </ListGroup.Item>
              {Boolean(totalExpectedTime) && (
                <ListGroup.Item className="d-flex justify-content-between align-items-center py-2 px-0">
                  {getIntl('Task completion')}: <span>{taskCompletionProgress}%</span>
                </ListGroup.Item>
              )}
              <ListGroup.Item className="d-flex justify-content-between align-items-center py-2 px-0">
                {getIntl('Status')}: <TaskStatusBadge status={status} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-end align-items-center py-2 px-0">
                <Button
                  tooltipKey="Details"
                  icon={<IconDetails />}
                  data-id={id}
                  linkProps={{ to: generatePath(Routes.Tasks, { id }) }}
                  size="small"
                />
                {status !== TaskStatus.Finished && (
                  <Button
                    marginLeft
                    tooltipKey="Edit"
                    icon={<IconEdit />}
                    data-id={id}
                    onClick={handleEditBtnClick}
                    size="small"
                  />
                )}
                <IconDeleteTableAction id={id} name={name || ''} marginLeft action={deleteTaskAction} />
              </ListGroup.Item>
            </ListGroup>
          </Popover.Content>
        </Popover>
      }
    >
      <ProgressBar
        variant={TaskStatusVariantMap[status]}
        style={{ cursor: 'pointer' }}
        now={totalExpectedTime ? taskCompletionProgress : 0}
        animated={status === TaskStatus.Ongoing}
        label={`${name} ${totalExpectedTime ? `(${taskCompletionProgress}%)` : ''}`}
      />
    </OverlayTrigger>
  );
};

export default TaskOverlay;
