import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { useLocale } from 'hooks';
import { portfolioIdSelector } from 'modules/layouts/selectors';
import { Tabs, Tab, ContentContainer, ContentCard } from 'components/_common';
import { Routes } from 'constants/index';
import ChartSaifiCML from './ChartSaifiCML';

const ViewSaifiCMLplots: React.FC = () => {
  const { getIntl } = useLocale();
  const portfolioId = useSelector(portfolioIdSelector);

  const renderCharts = (voltageLevel: 'MV' | 'LV') => (
    <ContentContainer>
      <Row xs={1} md={2}>
        <Col>
          <ContentCard marginBottom>
            <ChartSaifiCML
              key={voltageLevel}
              title={getIntl('Interruptions in {{voltageLevel}} grid (SAIFI)', { voltageLevel })}
              yAxisTitle={getIntl('Interruptions per customer')}
              src={`investment/saifi_plot?voltage_level=${voltageLevel}&portfolio_id=${portfolioId}`}
            />
          </ContentCard>
        </Col>
        <Col>
          <ContentCard>
            <ChartSaifiCML
              key={voltageLevel}
              title={getIntl('Customer minutes lost in {{voltageLevel}} grid (CML)', { voltageLevel })}
              yAxisTitle={getIntl('Interruption minutes per customer')}
              src={`investment/cml_plot?voltage_level=${voltageLevel}&portfolio_id=${portfolioId}`}
            />
          </ContentCard>
        </Col>
        <Col>
          <ContentCard>
            <ChartSaifiCML
              key={voltageLevel}
              title={getIntl('Interruptions in {{voltageLevel}} grid (SAIFI)', { voltageLevel })}
              yAxisTitle={getIntl('Interruptions per customer')}
              src={`investment/saifi_plot_ri?voltage_level=${voltageLevel}&portfolio_id=${portfolioId}`}
            />
          </ContentCard>
        </Col>
        <Col>
          <ContentCard>
            <ChartSaifiCML
              key={voltageLevel}
              title={getIntl('Customer minutes lost in {{voltageLevel}} grid (CML)', { voltageLevel })}
              yAxisTitle={getIntl('Interruption minutes per customer')}
              src={`investment/cml_plot_ri?voltage_level=${voltageLevel}&portfolio_id=${portfolioId}`}
            />
          </ContentCard>
        </Col>
      </Row>
    </ContentContainer>
  );

  return (
    <Tabs mountOnEnter unmountOnExit>
      <Tab eventKey={Routes.Reliability} title="MV" id="tab__reliability_mv_charts">
        {renderCharts('MV')}
      </Tab>
      <Tab eventKey={`${Routes.Reliability}${Routes.HASH.LV}`} title="LV" id="tab__reliability_lv_charts">
        {renderCharts('LV')}
      </Tab>
    </Tabs>
  );
};

export default ViewSaifiCMLplots;
