import React, { useCallback } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { assetsCountSelector, assetsSelector } from 'modules/assets/selectors';
import { fetchAssetsAction, deleteAssetAction } from 'modules/assets';
import { setModalConfirmationMetaAction } from 'modules/layouts';
import { DataTable, Button } from 'components/_common';
import { PaginationType, Routes } from 'constants/index';
import { IconEdit, IconDelete } from '@utiligize/shared/resources';

const AssetsTable: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const count = useSelector(assetsCountSelector);
  const items = useSelector(assetsSelector);

  const sendRequest = useCallback(() => dispatch(fetchAssetsAction()), [dispatch]);

  const handleDeleteBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const assetCode: string = String(event.currentTarget.getAttribute('data-id'));
      if (!assetCode) return;
      dispatch(setModalConfirmationMetaAction({ onConfirm: () => dispatch(deleteAssetAction(assetCode)) }));
    },
    [dispatch]
  );

  return (
    <DataTable
      paginationType={PaginationType.ASSETS}
      totalAmount={count}
      sendRequest={sendRequest}
      maxHeight="calc(100vh - 218px)"
    >
      {items?.map((item: Asset.Item) => (
        <tr key={item.id}>
          <td>{item.name || '-'}</td>
          <td>
            <Link to={generatePath(Routes.AssetDetails, { assetCode: item.code })}>{item.code}</Link>
          </td>
          <td className="text-right">
            <Button
              tooltipKey="Edit"
              icon={<IconEdit />}
              data-id={item.code}
              linkProps={{ to: generatePath(Routes.AssetDetails, { assetCode: item.code }) }}
              size="small"
            />
            <Button
              marginLeft
              tooltipKey="Delete"
              icon={<IconDelete />}
              data-id={item.code}
              onClick={handleDeleteBtnClick}
              size="small"
            />
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default AssetsTable;
