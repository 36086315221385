import styled from 'styled-components';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { MapContext } from 'context/map';
import { mapLayersSelector } from 'modules/map/selectors';
import { featureTogglesSelector, singleDiagramEnabledSelector } from 'modules/layouts/selectors';
import SingleLineDiagramControls from './SingleLineDiagramControls';
import AssetSearch from 'components/Map/common/AssetSearch';
import GroupDataThemes from './GroupDataThemes';
import GroupAssetsFilters from './GroupAssetsFilters';
import GroupAuxiliaryLayers from './GroupAuxiliaryLayers';
import { StorageKeys } from 'constants/index';

const Legend: React.FC = () => {
  const { map } = useContext(MapContext);
  const mapLayers = useSelector(mapLayersSelector);
  const singleDiagramEnabled = useSelector(singleDiagramEnabledSelector);
  const featureToggles = useSelector(featureTogglesSelector);

  const renderLegendBody = () => {
    if (!map || !mapLayers) return null;
    return (
      <StyledContentContainer>
        <GroupDataThemes />
        <GroupAssetsFilters />
        <GroupAuxiliaryLayers />
      </StyledContentContainer>
    );
  };

  return (
    <StyledContainer>
      {mapLayers && <AssetSearch singleDiagramEnabled={singleDiagramEnabled} />}
      {singleDiagramEnabled && featureToggles[StorageKeys.FEATURE_TOGGLE_SINGLE_LINE_CONTROLS] && (
        <SingleLineDiagramControls />
      )}
      {renderLegendBody()}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.purple500};
`;

const StyledContentContainer = styled.div`
  padding: 0 7px 15px 18px;
  flex-grow: 1;
  height: 1px;
  overflow-x: hidden;
  ${props => props.theme.mixins.scroll};
  scrollbar-gutter: stable;
`;

export default Legend;
