import styled from 'styled-components';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchScenarioYearsPossibleOptionsAction } from 'modules/options';
import { scenarioYearsOptionsSelector } from 'modules/options/selectors';
import { SelectYear, FormReactSelectProps, Checkbox } from 'components/_common';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: number | number[] | null;
  showAllAvailableYearsCheckbox?: boolean;
}

const SelectScenarioYearsPossible: React.FC<Props> = ({
  labelKey = 'Select years',
  value,
  showAllAvailableYearsCheckbox = false,
  onChange,
  ...rest
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch: Shared.CustomDispatch = useDispatch();
  const scenarioYearsOptions = useSelector(scenarioYearsOptionsSelector);

  useEffect(() => {
    if (scenarioYearsOptions) return;
    setLoading(true);
    dispatch(fetchScenarioYearsPossibleOptionsAction()).finally(() => setLoading(false));
  }, [scenarioYearsOptions, dispatch]);

  const handleCheckboxChange = useCallback(
    (event: React.SyntheticEvent) => {
      const { checked } = event.target as HTMLInputElement;
      onChange?.(checked ? scenarioYearsOptions?.map(i => ({ value: i })) : [], {} as any);
    },
    [onChange, scenarioYearsOptions]
  );

  const { range, values } = useMemo(
    () =>
      (scenarioYearsOptions || []).reduce(
        (acc: { range: number[]; values: number[] }, year: number) => {
          if (Array.isArray(value) ? value?.includes(year) : value === year) {
            acc.values.push(year);
          }
          acc.range.push(year);
          return acc;
        },
        { range: [], values: [] }
      ),
    [value, scenarioYearsOptions]
  );

  return (
    <>
      <SelectYear
        {...rest}
        range={range || []}
        isDisabled={!range?.length}
        isLoading={loading}
        labelKey={labelKey}
        onChange={onChange}
        value={values}
      />
      {showAllAvailableYearsCheckbox && (
        <StyledCheckbox
          className="icheck-primary"
          checked={Boolean(range?.length) && Array.isArray(value) && value?.length === scenarioYearsOptions?.length}
          labelKey="All available years"
          onChange={handleCheckboxChange}
        />
      )}
    </>
  );
};

const StyledCheckbox = styled(Checkbox)`
  margin-top: 1rem !important;
  margin-bottom: 0 !important;
`;

export default SelectScenarioYearsPossible;
