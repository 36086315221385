import { combineActions, handleActions } from 'redux-actions';
import {
  fetchPortfolioOptionsAction,
  fetchScenarioOptionsAction,
  fetchScenarioYearsPossibleOptionsAction,
  fetchSimulationOptionsAction,
  fetchPercentileOptionsAction,
  fetchScenarioYearsOptionsAction,
  fetchOutageTypeOptionsAction,
  fetchRoutesOptionsAction,
  fetchInvestmentScenariosOptionsAction,
  applyInvestmentScenariosOptionsAction,
  fetchComponentPriceScenarioOptionsAction,
  fetchComponentPriceTypeOptionsAction,
  fetchCnaimAssetCategoriesOptionsAction,
  fetchDERsDescriptionOptionsAction,
  resetDERsDescriptionOptionsAction,
  fetchDERsPointAssetsOptionsAction,
  fetchWorkTypeOptionsAction,
  fetchPrimarySubstationsOptionsAction,
  applyPrimarySubstationsFiltersAction,
  fetchPointTypeOptionsAction,
  fetchTransformerTypeOptionsAction,
  fetchCableTypeOptionsAction,
} from './actions';

const initialState: Options.Root = {
  portfolioOptions: null,
  scenarioOptionsHash: {},
  simulationOptionsHash: {},
  investmentScenariosOptionsHash: {},
  scenarioYearsOptions: null,
  percentilesOptionsHash: {},
  scenariosYearsOptionsHash: {},
  outageTypeOptions: null,
  routesOptions: null,
  componentPriceScenarioOptionsHash: {},
  componentPriceTypeOptions: null,
  cnaimAssetCategoriesOptionsHash: {} as Options.Root['cnaimAssetCategoriesOptionsHash'],
  DERsDescriptionOptionsHash: {},
  DERsPointAssetsOptionsHash: {},
  workTypeOptions: null,
  primarySubstationOptionsHash: {},
  pointTypeOptionsHash: {},
  transformerTypeOptionsHash: {},
  cableTypeOptionsHash: {},
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [combineActions(
    fetchPortfolioOptionsAction,
    fetchScenarioOptionsAction,
    fetchScenarioYearsPossibleOptionsAction,
    fetchSimulationOptionsAction,
    fetchPercentileOptionsAction,
    fetchScenarioYearsOptionsAction,
    fetchOutageTypeOptionsAction,
    fetchRoutesOptionsAction,
    fetchInvestmentScenariosOptionsAction,
    fetchComponentPriceScenarioOptionsAction,
    fetchComponentPriceTypeOptionsAction,
    fetchCnaimAssetCategoriesOptionsAction,
    fetchDERsDescriptionOptionsAction,
    resetDERsDescriptionOptionsAction,
    fetchDERsPointAssetsOptionsAction,
    fetchWorkTypeOptionsAction,
    fetchPrimarySubstationsOptionsAction,
    fetchPointTypeOptionsAction,
    fetchTransformerTypeOptionsAction,
    fetchCableTypeOptionsAction
  ) as any]: {
    next: (state: Options.Root, action: Shared.ReduxAction<Partial<Options.Root>>): Options.Root => ({
      ...state,
      ...action.payload,
    }),
  },
};

export {
  fetchPortfolioOptionsAction,
  fetchScenarioOptionsAction,
  fetchScenarioYearsPossibleOptionsAction,
  fetchSimulationOptionsAction,
  fetchPercentileOptionsAction,
  fetchScenarioYearsOptionsAction,
  fetchOutageTypeOptionsAction,
  fetchRoutesOptionsAction,
  fetchInvestmentScenariosOptionsAction,
  applyInvestmentScenariosOptionsAction,
  fetchComponentPriceScenarioOptionsAction,
  fetchComponentPriceTypeOptionsAction,
  fetchCnaimAssetCategoriesOptionsAction,
  fetchDERsDescriptionOptionsAction,
  resetDERsDescriptionOptionsAction,
  fetchDERsPointAssetsOptionsAction,
  fetchWorkTypeOptionsAction,
  fetchPrimarySubstationsOptionsAction,
  applyPrimarySubstationsFiltersAction,
  fetchPointTypeOptionsAction,
  fetchTransformerTypeOptionsAction,
  fetchCableTypeOptionsAction,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
