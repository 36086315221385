import React, { useMemo } from 'react';
import { useLocale } from 'hooks';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';
import { SelectLoadingUnitValues } from 'constants/index';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: SelectLoadingUnitValues | undefined;
}

const SelectLoadingUnit: React.FC<Props> = ({ value = null, ...props }) => {
  const { getIntl } = useLocale();

  const SelectLoadingUnitValuesLabelsMap = useMemo(
    () => ({
      [SelectLoadingUnitValues.True]: 'Ampere',
      [SelectLoadingUnitValues.False]: 'Power',
    }),
    []
  );

  const { options, selectValue } = useMemo(
    () =>
      Object.keys(SelectLoadingUnitValues).reduce(
        (acc: any, key: string) => {
          const item = {
            value: key,
            label: getIntl(SelectLoadingUnitValuesLabelsMap[key as SelectLoadingUnitValues]),
          };
          if (value === key) acc.selectValue = item;
          acc.options.push(item);
          return acc;
        },
        { options: [], selectValue: null }
      ),
    [value, SelectLoadingUnitValuesLabelsMap, getIntl]
  );

  return <FormReactSelect value={selectValue} options={options} isSearchable={false} {...props} />;
};

export default SelectLoadingUnit;
