import React, { useCallback, useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useEventPreventedExec } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import { assetSubComponentModalIdSelector } from 'modules/layouts/selectors';
import { setLayoutAction } from 'modules/layouts';
import { subComponentSelectorFactory } from 'modules/assets/selectors';
import { Modal, Button, FormInput, SelectAssetCategories, HiddenFormSubmit } from 'components/_common';
import { IconPlus } from '@utiligize/shared/resources';
import useConfiguredFormik from './useConfiguredFormik';

const AssetSubComponentsModal: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const id: number | null = useSelector(assetSubComponentModalIdSelector);
  const assetSubComponent: Asset.SubComponentType | undefined = useSelector(subComponentSelectorFactory(id));
  const isEditMode: boolean = Boolean(id && assetSubComponent);
  const [show, setShow] = useState(false);

  const toggleModal = useCallback((): void => {
    // Reset redux layouts state for isEditMode
    if (show && id) dispatch(setLayoutAction({ assetSubComponentModalId: null }));
    setShow(!show);
  }, [id, show, dispatch]);

  const { values, touched, errors, isSubmitting, handleChange, handleBlur, submitForm, setValues, resetForm } =
    useConfiguredFormik({ id, toggleModal });
  const handleFormSubmit = useEventPreventedExec(submitForm);

  useEffect(() => {
    if (!show) resetForm();
  }, [show, resetForm]);

  useEffect(() => {
    if (!assetSubComponent) return;
    setValues({
      name: assetSubComponent.name,
      assetCategoryCode: assetSubComponent.assetCategoryCode,
    });
    setShow(true);
  }, [assetSubComponent, setValues]);

  return (
    <>
      <Button icon={<IconPlus />} labelKey="Create sub-component type" onClick={toggleModal} variant="primary" />
      <Modal
        show={show}
        onHide={toggleModal}
        titleKey={isEditMode ? 'Edit sub-component type' : 'Create sub-component type'}
        cancelButtonProps={{
          disabled: isSubmitting,
          onClick: toggleModal,
        }}
        submitButtonProps={{
          labelKey: isEditMode ? 'Update' : 'Create',
          loading: isSubmitting,
          onClick: handleFormSubmit,
        }}
      >
        <Form onSubmit={handleFormSubmit}>
          <Form.Group>
            <FormInput
              autoFocus
              labelKey="Name"
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              errorKey={Boolean(touched.name && errors.name) ? (errors.name as string) : ''}
              isNew
            />
          </Form.Group>
          <Form.Group>
            <SelectAssetCategories variant="small" value={values?.assetCategoryCode} isDisabled />
          </Form.Group>
          <HiddenFormSubmit />
        </Form>
      </Modal>
    </>
  );
};

export default AssetSubComponentsModal;
