import React, { useMemo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { portfolioIdSelector, scenarioIdSelector } from 'modules/layouts/selectors';
import { scenariosYearsOptionsSelector } from 'modules/options/selectors';
import { fetchScenarioYearsOptionsAction } from 'modules/options';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  labelKey?: string;
  value: number | null;
  setInitialValue?: boolean;
  isInCustomerRightsProfileModal?: boolean;
}

const SelectScenarioYears: React.FC<Props> = ({
  labelKey = 'Scenarios year',
  value,
  isInCustomerRightsProfileModal = false,
  setInitialValue,
  onChange,
  ...rest
}) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const options = useSelector(scenariosYearsOptionsSelector);
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);

  useEffect(() => {
    if (!portfolioId || !scenarioId || options) return;
    setIsLoading(true);
    dispatch(fetchScenarioYearsOptionsAction({ portfolioId, scenarioId })).finally(() => setIsLoading(false));
  }, [dispatch, portfolioId, scenarioId, options, setInitialValue]);

  const { selectOptions, selectValue } = useMemo(
    () =>
      (options || []).reduce(
        (
          acc: { selectOptions: Type.SelectOption<number>[]; selectValue: Type.SelectOption<number> | null },
          option
        ) => {
          if (isInCustomerRightsProfileModal && option.value > new Date().getFullYear()) return acc;
          if (value === option.value) acc.selectValue = option;
          acc.selectOptions.push(option);
          return acc;
        },
        { selectOptions: [], selectValue: null }
      ),
    [value, options, isInCustomerRightsProfileModal]
  );

  return (
    <FormReactSelect
      isLoading={isLoading}
      isDisabled={isLoading}
      labelKey={labelKey}
      options={selectOptions}
      value={selectValue}
      isSearchable={false}
      onChange={onChange}
      {...rest}
    />
  );
};

export default SelectScenarioYears;
