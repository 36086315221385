import React, { useMemo } from 'react';
import { useLocale } from 'hooks';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';
import { SelectSolvedValues } from 'constants/index';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: SelectSolvedValues | undefined;
}

const SelectSolved: React.FC<Props> = ({ value = null, ...props }) => {
  const { getIntl } = useLocale();

  const SelectSolvedValuesLabelsMap = useMemo(
    () => ({
      [SelectSolvedValues.False]: 'Show all',
      [SelectSolvedValues.True]: 'Hide solved',
      [SelectSolvedValues.Solved]: 'Only solved',
    }),
    []
  );

  const { options, selectValue } = useMemo(
    () =>
      Object.keys(SelectSolvedValues).reduce(
        (acc: any, key: string) => {
          const item = {
            value: key,
            label: getIntl(SelectSolvedValuesLabelsMap[key as SelectSolvedValues]),
          };
          if (value === key) acc.selectValue = item;
          acc.options.push(item);
          return acc;
        },
        { options: [], selectValue: null }
      ),
    [value, SelectSolvedValuesLabelsMap, getIntl]
  );

  return <FormReactSelect value={selectValue} options={options} isSearchable={false} {...props} />;
};

export default SelectSolved;
