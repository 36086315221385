import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useEventPreventedExec, useLocale } from 'hooks';
import { Modal, Button, Checkbox } from 'components/_common';
import { IconUpload } from '@utiligize/shared/resources';
import useConfiguredFormik from './useConfiguredFormik';

const ModalUploadMaterials: React.FC = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [show, setShow] = useState(false);
  const { getIntl } = useLocale();

  const toggleModal = useCallback((): void => setShow(show => !show), []);

  const { values, errors, touched, isSubmitting, submitForm, resetForm, setFieldValue } = useConfiguredFormik({
    toggleModal,
  });
  const handleFormSubmit = useEventPreventedExec(submitForm);

  useEffect(() => {
    if (!show) resetForm();
  }, [show, resetForm]);

  const handleCheckboxChange = useCallback(
    (event: React.SyntheticEvent) => {
      const { name, checked } = event.target as HTMLInputElement;
      setFieldValue(name, checked);
    },
    [setFieldValue]
  );

  const handleUploadButtonClick = useCallback(() => {
    inputRef.current!.value = '';
    inputRef.current?.click();
  }, []);

  const handleUploadInputChange = useCallback(() => {
    const files = inputRef.current?.files;
    if (files?.[0]) setFieldValue('file', files?.[0]);
  }, [setFieldValue]);

  return (
    <>
      <Button icon={<IconUpload />} labelKey="Upload materials" onClick={toggleModal} variant="primary" />
      <Modal
        show={show}
        onHide={toggleModal}
        titleKey="Upload materials"
        cancelButtonProps={{
          disabled: isSubmitting,
          onClick: toggleModal,
        }}
        submitButtonProps={{
          labelKey: 'Upload',
          loading: isSubmitting,
          onClick: handleFormSubmit,
        }}
      >
        <Form onSubmit={handleFormSubmit}>
          <Row>
            <Col xs="auto">
              <Button icon={<IconUpload />} labelKey="Upload excel" onClick={handleUploadButtonClick} />
              <input
                ref={inputRef}
                onChange={handleUploadInputChange}
                className="d-none"
                type="file"
                accept=".xlsx, .xls"
              />
            </Col>
            <Col xs="auto">
              <Checkbox
                className="pl-0 icheck-primary"
                name="replace"
                checked={values.replace}
                labelKey="Replace"
                onChange={handleCheckboxChange}
              />
            </Col>
          </Row>
          {values.file && <Form.Text className="text-muted">{values.file.name}</Form.Text>}
          {Boolean(touched.file && errors.file) && (
            <Form.Control.Feedback className="d-block" type="invalid">
              {getIntl(errors.file as string)}
            </Form.Control.Feedback>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default ModalUploadMaterials;
