import { createSelector } from 'reselect';
import { componentPriceTypeOptionsSelector } from 'modules/options/selectors';
import { MAX_FORECASTED_SCENARIOS } from 'constants/index';

// ------------------------------------
// Selectors
// ------------------------------------
export const rootSelector = (state: State.Root) => state.setup;

export const forecastedScenariosCountSelector = createSelector(rootSelector, setup => setup.forecastedScenariosCount);

export const isMaxForecastedScenariosReachedSelector = createSelector(
  forecastedScenariosCountSelector,
  count => count >= MAX_FORECASTED_SCENARIOS
);

const forecastedScenariosHashSelector = createSelector(rootSelector, setup => setup.forecastedScenariosHash);

export const forecastedScenariosSelector = createSelector(forecastedScenariosHashSelector, forecastedScenariosHash =>
  Object.values(forecastedScenariosHash)
);

export const defaultForecastedScenarioNameSelector = createSelector(
  forecastedScenariosSelector,
  forecastedScenarios => forecastedScenarios.find(i => i.default)?.description || ''
);

export const forecastedScenarioSelectorFactory = (id: number | null) =>
  createSelector(
    forecastedScenariosHashSelector,
    (forecastedScenariosHash: Setup.Root['forecastedScenariosHash']): Setup.ForecastedScenario =>
      forecastedScenariosHash[`_${id}_`]
  );

export const investmentScenariosHashSelector = createSelector(rootSelector, setup => setup.investmentScenariosHash);

export const investmentScenariosSelector = createSelector(investmentScenariosHashSelector, investmentScenariosHash =>
  Object.values(investmentScenariosHash)
);

export const investmentScenariosMetaCurrencySelector = createSelector(
  rootSelector,
  setup => setup.investmentScenariosMetaCurrency
);

export const investmentScenarioSelectorFactory = (id: number | null) =>
  createSelector(
    investmentScenariosHashSelector,
    (investmentScenariosHash): Setup.InvestmentScenario => investmentScenariosHash[`_${id}_`]
  );

export const investmentScenarioPriceSelector = createSelector(
  investmentScenariosSelector,
  (investmentScenarios): Setup.InvestmentScenario['price_per_mwh'] | null =>
    investmentScenarios?.[0]?.price_per_mwh || null
);

export const CO2eSelector = createSelector(rootSelector, setup => setup.CO2e);

export const rawDataCountSelector = createSelector(rootSelector, setup => setup.rawDataCount);

const rawDataHashSelector = createSelector(rootSelector, setup => setup.rawDataHash);

export const rawDataSelector = createSelector(rawDataHashSelector, rawDataHash => Object.values(rawDataHash));

export const rawDataMetaSelector = createSelector(rootSelector, setup => setup.rawDataMeta);

export const componentPriceScenariosCountSelector = createSelector(
  rootSelector,
  setup => setup.componentPriceScenariosCount
);

export const componentPriceScenariosHashSelector = createSelector(
  rootSelector,
  setup => setup.componentPriceScenariosHash
);

export const componentPriceScenariosSelector = createSelector(
  componentPriceScenariosHashSelector,
  componentPriceScenariosHash => Object.values(componentPriceScenariosHash)
);

export const componentPriceScenarioSelectorFactory = (id: number | null) =>
  createSelector(
    componentPriceScenariosHashSelector,
    (componentPriceScenariosHash): Setup.ComponentPriceScenario => componentPriceScenariosHash[`_${id}_`]
  );

const componentPricesHashSelector = createSelector(rootSelector, setup => setup.componentPricesHash);

export const componentPricesSelector = createSelector(
  componentPricesHashSelector,
  (componentPricesHash): Setup.ComponentPrice[] => Object.values(componentPricesHash)
);

export const componentPriceSelectorFactory = (id: number | null) =>
  createSelector(
    componentPricesHashSelector,
    (componentPricesHash): Setup.ComponentPrice => componentPricesHash[`_${id}_`]
  );

export const componentPricesCountSelector = createSelector(rootSelector, (setup): number => setup.componentPricesCount);

export const allTableComponentPricesFetchedSelector = createSelector(
  [componentPriceTypeOptionsSelector],
  componentPriceTypeOptions => Boolean(componentPriceTypeOptions?.length)
);

export const investmentLimitationsByVoltageNameHashSelector = createSelector(
  [rootSelector],
  setup => setup.investmentLimitationsByVoltageNameHash
);

export const investmentLimitationVoltageUnitSelector = createSelector(
  rootSelector,
  setup => setup.investmentLimitationVoltageUnit
);

const investmentBudgetHashSelector = createSelector(rootSelector, setup => setup.investmentBudgetHash);

export const investmentBudgetSelector = createSelector(investmentBudgetHashSelector, investmentBudgetHash =>
  Object.values(investmentBudgetHash)
);

export const investmentBudgetCountSelector = createSelector(rootSelector, setup => setup.investmentBudgetCount);

export const investmentBudgetMetaSelector = createSelector(rootSelector, setup => setup.investmentBudgetMeta);

export const scenarioCalculationHashSelector = createSelector(rootSelector, setup => setup.scenarioCalculationHash);

export const scenarioCalculationSelector = createSelector(scenarioCalculationHashSelector, scenarioCalculationHash =>
  Object.values(scenarioCalculationHash)
);

// ------------------------------------
// Work Price Selectors
// ------------------------------------
export const workPricesCountSelector = createSelector(rootSelector, setup => setup.workPricesCount);

const workPricesHashSelector = createSelector(rootSelector, setup => setup.workPricesHash);

export const workPricesSelector = createSelector(workPricesHashSelector, workPricesHash =>
  Object.values(workPricesHash)
);

export const workPriceSelectorFactory = (id: Setup.WorkPrice['work_type_price_id'] | null) =>
  createSelector(workPricesHashSelector, (workPricesHash): Setup.WorkPrice => workPricesHash[`_${id}_`]);
