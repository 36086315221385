import axios from 'axios';
import { createAction } from 'redux-actions';
import { setSuccessToastAction } from 'modules/layouts';
import { paginationSelectorFactory, portfolioIdSelector } from 'modules/layouts/selectors';
import { simulationVersionIdSelector } from 'modules/options/selectors';
import { mapPanelSelectedAssetUUIDSelector } from 'modules/router/selectors';
import { getGalleryImages } from 'utils';
import { _keyBy } from '@utiligize/shared/utils';
import { AssetCategories, AssetLifeAPI, PaginationType } from 'constants/index';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchAssetCategoriesAction: any = createAction(
  'assets/FETCH_CATEGORIES',
  async () => (): Promise<Pick<Asset.Root, 'categories'>> => {
    return axios
      .get('api/admin/v1/secure/assetcategories', {
        params: { limit: null, offset: null, sort: 'ASC', column: 'name', query: null },
      })
      .then((res: any) => ({ categories: res.data.assetCategories }));
  }
);

export const fetchAssetsAction = createAction(
  'assets/FETCH_ASSETS',
  async () =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Asset.Root, 'assetsCount' | 'assetsHash'>> => {
      const state = getState();
      const { limit, offset, sort, column, query, filters } = paginationSelectorFactory(PaginationType.ASSETS)(state);
      return axios
        .get('api/admin/v1/secure/assets', {
          params: {
            assetCategoryCode: filters?.assetcategoryCode,
            limit,
            offset,
            sort,
            column,
            query,
          },
        })
        .then((res: any) => ({
          assetsCount: res.data.count,
          assetsHash: _keyBy(res.data.rows, (item: Asset.Item) => `_${item.id}_`),
        }));
    }
);

export const fetchAssetAction = createAction(
  'assets/FETCH_ASSET',
  ({ uuid, assetCode, versionId }: { uuid?: string; assetCode?: string; versionId?: number | null }) =>
    (): Promise<Asset.ExtendedItem | null> => {
      return axios
        .get(`api/admin/v2/secure/assets/${uuid || assetCode}`, { params: { versionId } })
        .then(res => {
          const attachments = res.data.attachments || res.data.liveData?.attachments;
          const images = attachments
            ? getGalleryImages(
                attachments.filter((attachment: Type.Attachment) => attachment.width && attachment.height)
              )
            : undefined;
          return {
            ...res.data,
            images,
            ...(res.data.liveData ? { liveData: { ...res.data.liveData, images } } : null),
          };
        })
        .catch(() => null);
    }
);

export const createAssetAction = createAction(
  'assets/CREATE_ASSET',
  (assetMeta: Asset.Item) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      return axios
        .post(`api/admin/v1/secure/assets?assetCategoryCode=${AssetCategories.BD}`, assetMeta)
        .then(async () => {
          // redux state must be updated with for assigned meter number
          await dispatch(fetchSmartMetersAction());
          dispatch(setSuccessToastAction('Asset has been created'));
        });
    }
);

export const updateAssetAction = createAction(
  'assets/UPDATE_ASSET',
  (assetMeta: Asset.Item) =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<void> => {
      return axios.put(`api/admin/v1/secure/assets/${assetMeta.assetCode}`, assetMeta).then(async () => {
        const state = getState();
        // redux state must be updated with for assigned meter number
        if (assetMeta.elInTablID) await dispatch(fetchSmartMetersAction());
        // update asset meta for UnifiedAssetPanel
        const uuid = mapPanelSelectedAssetUUIDSelector(state);
        const versionId = simulationVersionIdSelector(state);
        if (uuid && versionId) {
          await dispatch(fetchAssetAction({ uuid, versionId }));
        }
        dispatch(setSuccessToastAction('Asset has been saved'));
      });
    }
);

export const deleteAssetAction = createAction('assets/DELETE_ASSET', async (assetCode: string) => {
  return (dispatch: Shared.CustomDispatch): Promise<void> => {
    return axios.delete(`api/admin/v1/secure/assets/${assetCode}`).then(async () => {
      await dispatch(fetchAssetsAction());
      dispatch(setSuccessToastAction('Asset has been deleted'));
    });
  };
});

export const deleteAssetPhotosAction = createAction(
  'assets/DELETE_ASSET_PHOTOS',
  ({ uuid, assetCode, attachmentKeys }: { uuid?: string; assetCode?: string; attachmentKeys: string[] }) =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<void> => {
      const versionId = simulationVersionIdSelector(getState());
      return axios
        .post(`api/admin/v2/secure/assets/${uuid || assetCode}/attachments/delete`, { attachmentKeys, versionId })
        .then(() => {
          dispatch(setSuccessToastAction('Selected photos has been deleted'));
        });
    }
);

export const fetchModelsByAssetCategoryAction = createAction(
  'assets/FETCH_MODELS_BY_CATEGORY',
  (categoryCode: Type.AssetCategories) => (): Promise<AssetModels.Item[]> => {
    return axios.get(`api/admin/v1/secure/assets/${categoryCode}/assetmodels`).then(res => res.data.rows);
  }
);

export const fetchSubComponentsAction: any = createAction(
  'assets/FETCH_SUB_COMPONENTS',
  async (
    {
      skipPagination,
      assetCategoryCode,
      skipStoreUpdate,
    }: { assetCategoryCode?: Type.AssetCategories; skipPagination?: boolean; skipStoreUpdate?: true | undefined } = {
      skipPagination: false,
      skipStoreUpdate: undefined,
    }
  ) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Asset.Root, 'subComponentsCount' | 'subComponentsHash'>> => {
      const state = getState();
      const { limit, offset, sort, column, query, filters } = paginationSelectorFactory(PaginationType.SUB_COMPONENTS)(
        state
      );
      return axios
        .get('api/admin/v1/secure/subcomponentsType', {
          params: {
            assetCategoryCode: assetCategoryCode || filters?.assetcategoryCode,
            limit: skipPagination ? null : limit,
            offset: skipPagination ? null : offset,
            sort,
            column,
            query: skipPagination ? null : query,
          },
        })
        .then((res: any) => ({
          subComponentsCount: res.data.count,
          subComponentsHash: _keyBy(res.data.rows, (item: Asset.SubComponentType) => `_${item.id}_`),
          skipStoreUpdate,
        }))
        .catch(() => ({ subComponentsCount: 0, subComponentsHash: {} }));
    }
);

export const createSubComponentAction = createAction(
  'assets/CREATE_SUB_COMPONENT',
  async (data: { name: string; assetCategoryCode: Type.AssetCategories }) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      return axios.post('api/admin/v1/secure/subcomponentsType', data).then(async (res: any) => {
        await dispatch(fetchSubComponentsAction({ assetCategoryCode: data.assetCategoryCode }));
        dispatch(setSuccessToastAction('Asset sub-component type has been created'));
        return res.data;
      });
    }
);

export const updateSubComponentAction = createAction(
  'assets/UPDATE_SUB_COMPONENT',
  async (data: { id: number; name: string; assetCategoryCode: Type.AssetCategories }) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      return axios.put(`api/admin/v1/secure/subcomponentsType`, data).then(async () => {
        await dispatch(fetchSubComponentsAction({ assetCategoryCode: data.assetCategoryCode }));
        dispatch(setSuccessToastAction('Asset sub-component type has been updated'));
      });
    }
);

export const deleteSubComponentAction = createAction(
  'assets/DELETE_SUB_COMPONENT',
  (id: number, assetCategoryCode: Type.AssetCategories) => {
    return (dispatch: Shared.CustomDispatch): Promise<void> => {
      return axios.delete(`api/admin/v1/secure/subcomponentsType/${id}`).then(async () => {
        await dispatch(fetchSubComponentsAction({ assetCategoryCode }));
        dispatch(setSuccessToastAction('Asset sub-component type has been deleted'));
      });
    };
  }
);

export const fetchSmartMetersAction = createAction(
  'assets/FETCH_SMART_METERS',
  () => (): Promise<Pick<Asset.Root, 'smartMetersHash' | 'smartMetersHashFetched'>> =>
    axios.get('api/admin/v1/secure/smartMeters').then(res => ({
      smartMetersHash: _keyBy(res.data.rows, (item: Asset.SmartMeter) => `_${item.elInTablID}_`),
      smartMetersHashFetched: true,
    }))
);

export const fetchAssetTaskCompletionsAction = createAction(
  'assets/FETCH_ASSET_TASK_COMPLETIONS',
  async ({ uuid, assetCode }: { uuid?: string; assetCode?: string }) =>
    (): Promise<Pick<Asset.Root, 'taskCompletions'>> =>
      axios.get(`api/admin/v2/secure/assets/${uuid || assetCode}/taskCompletions`).then((res: any) => ({
        taskCompletions: res.data.taskCompletions,
      }))
);

export const fetchMapAssetsByCodeAction: any = createAction(
  'assets/FETCH_MAP_ASSETS_BY_CODE',
  async (query: string, withGeometry: boolean) =>
    (dispatch: Function, getState: () => State.Root): Promise<Asset.MapAssetCodeItem[]> => {
      const state = getState();
      const portfolioId = portfolioIdSelector(state);
      const versionId = simulationVersionIdSelector(state);

      return AssetLifeAPI.get('map/search_assets_by_code', {
        params: { asset_code: query, with_geometry: withGeometry, portfolio_id: portfolioId, version_id: versionId },
      })
        .then(res => res.data)
        .catch(() => []);
    }
);

export const fetchTaskAssetsByCodeAction: any = createAction(
  'assets/FETCH_TASK_ASSETS_BY_CODE',
  async (query: string) => (): Promise<Asset.TaskAssetCodeItem[]> => {
    return axios
      .get('api/admin/v1/secure/assets/codes/search', { params: { name: query } })
      .then(res =>
        res.data.map(({ name, code, assetCode, category }: any) => ({
          assetCode: assetCode || name || code,
          categoryCode: category?.code,
        }))
      )
      .catch(() => []);
  }
);

export const fetchInstallationNumberAutoCompleteAction: any = createAction(
  'assets/FETCH_INSTALLATION_NUMBERS_BY_CODE',
  async (query: string) => (): Promise<Asset.TaskAssetCodeItem[]> =>
    axios
      .get(`api/admin/v1/secure/smartMeters/codes/validate/${query}`)
      .then(res => res.data.map((assetCode: string) => ({ assetCode })))
      .catch(() => [])
);
