import { createSelector } from 'reselect';
import {
  portfolioOptionSelector,
  scenarioSelectedOptionSelector,
  simulationOptionSelector,
} from 'modules/options/selectors';
import { appRootSelector } from './app/selectors';
import { getStorageItem } from 'utils';
import { StorageKeys } from 'constants/index';

export const mixpanelPageViewEventPayloadSelector = createSelector(
  [appRootSelector, portfolioOptionSelector, scenarioSelectedOptionSelector, simulationOptionSelector],
  (app, portfolioOption, scenarioSelectedOption, simulationOption) => {
    const claims: Users.Claims | null = getStorageItem(StorageKeys.USER_CLAIMS);
    const lastRoute = getStorageItem(StorageKeys.LAST_ROUTE);

    const skipTracking =
      !process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN ||
      !portfolioOption?.label ||
      !scenarioSelectedOption?.label ||
      !app.appCurrentUserFetched ||
      !app.selectedTimezone ||
      claims?.email.includes('@this.email.is.invalid');

    if (skipTracking) return null;

    return {
      distinct_id: claims?.email,
      previous_route: lastRoute,
      is_admin: app.isAdmin,
      tenant: getStorageItem(StorageKeys.SELECTED_TENANT),
      portfolio: portfolioOption?.label,
      scenario: scenarioSelectedOption?.label,
      simulation: simulationOption?.label,
      user: app.fullName,
      app_version: process.env.REACT_APP_VERSION,
      app_language: app.lang,
      app_timezone: app.selectedTimezone,
    };
  }
);
