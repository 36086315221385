import axios from 'axios';
import { createAction } from 'redux-actions';
import mixpanel from 'mixpanel-browser';
import { mixpanelPageViewEventPayloadSelector } from '../composite.selectors';

// ------------------------------------
// Actions
// ------------------------------------
export const setLangAction = createAction('app/SET_LANG');

export const setTimezoneAction = createAction('app/SET_TIMEZONE');

export const fetchCurrentUserInfoAction: any = createAction(
  'app/FETCH_CURRENT_USER',
  async (): Promise<Omit<App.Root, 'lang' | 'appCurrentUserFetched'>> => {
    return axios.get('api/admin/v1/secure/permissions/users/current').then(res => res?.data);
  }
);

export const fetchWikiPageAction: any = createAction(
  'app/FETCH_WIKI_PAGE',
  async (pageId: number): Promise<Type.Hash<App.SidebarHelpMenuPage>> => {
    return axios
      .post('/api/admin/v1/secure/wiki/graphql', {
        data: `{\n\t"query": "query{pages{single(id:${pageId}){id title description content render createdAt updatedAt}}}",\n\t"variables": {}\n}`,
      })
      .then(res => ({ [pageId]: res.data.pages.single }));
  }
);

export const setAppErrorAction = createAction('app/SET_APP_ERROR');

export const trackPageViewAction = createAction(
  'app/TRACK_MAP_LAYERS',
  async () => (dispatch: Shared.CustomDispatch, getState: () => State.Root) => {
    const mixpanelPageViewEventPayload = mixpanelPageViewEventPayloadSelector(getState());
    mixpanel.track('Dashboard page view event', mixpanelPageViewEventPayload!);
  }
);

export const trackMapLayersAction = createAction(
  'app/TRACK_MAP_LAYERS',
  async (payload: any) => (dispatch: Shared.CustomDispatch, getState: () => State.Root) => {
    const mixpanelPageViewEventPayload = mixpanelPageViewEventPayloadSelector(getState());
    mixpanel.track('Dashboard map layer event', { ...mixpanelPageViewEventPayload, ...payload });
  }
);
