import { FormikHelpers } from 'formik';
import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { fetchLogMaintenanceFormsAction } from 'modules/forms';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: number | null;
  assetCategoryCode: Type.AssetCategories | null;
  assetCodes?: string[];
  setFieldValue?: FormikHelpers<Tasks.CreateTaskActionProps>['setFieldValue'];
  setFieldTouched?: FormikHelpers<Tasks.CreateTaskActionProps>['setFieldTouched'];
}

type FormItem = { id: number; name: string; assetCodes: string[] };

const SelectLogMaintenanceForms: React.FC<Props> = ({
  labelKey = 'Form',
  errorKey = '',
  isDisabled = false,
  name,
  value,
  assetCategoryCode,
  assetCodes,
  setFieldValue,
  setFieldTouched,
  ...props
}) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [forms, setForms] = useState<FormItem[]>([]);

  useEffect(() => {
    if (!assetCategoryCode) return setLoading(false);
    dispatch(fetchLogMaintenanceFormsAction(assetCategoryCode) as any)
      .then((action: Shared.ReduxAction<FormItem[]>) => {
        setForms(
          action.payload.filter(
            form =>
              !form.assetCodes.length ||
              !assetCodes ||
              assetCodes.every((code: string) => form.assetCodes.includes(code))
          )
        );
        setLoading(false);
      })
      .catch(console.error);
  }, [assetCategoryCode, assetCodes, dispatch]);

  const formsSelectOptions = useMemo(() => forms.map(({ id, name }) => ({ value: id, label: name })), [forms]);

  const handleChange = useCallback((v: Type.SelectOption) => setFieldValue?.(name!, v.value), [name, setFieldValue]);
  const handleBlur = useCallback(() => setFieldTouched?.(name!), [name, setFieldTouched]);

  return (
    <FormReactSelect
      labelKey={labelKey}
      errorKey={errorKey}
      isLoading={loading}
      isDisabled={loading || isDisabled}
      value={value ? { value, label: forms.find(f => f.id === value)?.name || '' } : null}
      options={formsSelectOptions}
      onChange={handleChange}
      onBlur={handleBlur}
      {...props}
    />
  );
};

export default SelectLogMaintenanceForms;
